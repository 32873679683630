import axios from 'axios';
import { ULTRASONIC_BASE_PATH } from './api-base-paths';

 export const getAllUltrasonics = () => {
    try {
      return axios.get(`${ULTRASONIC_BASE_PATH}/getAllUltrasonic`);
    } catch (error) {
      console.error('Error fetching all Ultrasonics', error);
      
    }
  };
  
  export const createUltrasonic = (data) => {
      return axios.post(`${ULTRASONIC_BASE_PATH}/createUltrasonic`, data);
    };
   
    export const getUltrasonicByCertificateId = (id) => {
      return axios.get(`${ULTRASONIC_BASE_PATH}/getUltrasonicByCertificateId/${id}`);
    }; 
  
  
    export const getAutoCertificateNo = (date) => {
      const data = {
        date: date
      };
      return axios.post(`${ULTRASONIC_BASE_PATH}/getAutoCertificateNo`,data);
    }; 
   
  
    export const getUltrasonicById = (id) => {
      return axios.get(`${ULTRASONIC_BASE_PATH}/getUltrasonicById/${id}`);
    }; 
  
    export const updateUltrasonic = (id, data) => {
      return axios.put(`${ULTRASONIC_BASE_PATH}/updateUltrasonic/${id}`, data);
    };
  
    export const deleteUltrasonic = (id) => {
      return axios.delete(`${ULTRASONIC_BASE_PATH}/deleteUltrasonic/${id}`);
    };  
  
    export const createUltrasonicDescription = (data) => {
      return axios.post(`${ULTRASONIC_BASE_PATH}/createUltrasonicDescription`, data);
    };
  
    export const deleteUltrasonicDescription = (id) => {
      return axios.delete(`${ULTRASONIC_BASE_PATH}/deleteUltrasonicDescription/${id}`);
    }; 
    
    export const getUltrasonicDescriptionById = (id) => {
      return axios.get(`${ULTRASONIC_BASE_PATH}/getUltrasonicDescriptionById/${id}`);
    }; 
    
    export const getUltrasonicPDF = (id) => {
      console.log("id", id )
      return axios.get(`${ULTRASONIC_BASE_PATH}/getUltrasonicPDF/${id}`);
    };
    
    export const getPrint = async () => {
      try {
          const response = await axios.get(`${ULTRASONIC_BASE_PATH}/getPrint`);
          return response.data;
      } catch (error) {
          console.error('Error fetching all customer:', error);
          throw error;
      }
  };