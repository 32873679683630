import React from 'react'
import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoadingSpinner from "../ui/LoadingSpinner";
import Db from '../../Pages/Db';
import Material from '../../Pages/Master/Material';
import Customer from '../../Pages/Master/Customer';
import UT from '../../Pages/Master/UT';
import NEFT from '../../Pages/Master/NEFT';
import Element from '../../Pages/Master/Element';
import Branch from '../../Pages/Master/Branch';
import Technique from '../../Pages/Master/Technique';
import Instrument from '../../Pages/Master/Instrument';
import Probe from '../../Pages/Master/Probe';

import BillMaster from '../../Pages/Transaction/BillMaster/BillMaster';
import Dye from '../../Pages/Transaction/Dye/Dye';
import Eddy from '../../Pages/Transaction/Eddy/Eddy';
import Magnetic from '../../Pages/Transaction/Magnetic/Magnetic';
import PMI from '../../Pages/Transaction/PMI/PMI';
// import PMI1 from '../../Pages/Transaction.js/PMI1';
import Spark from '../../Pages/Transaction/Spark/Spark';
import Spectro from '../../Pages/Transaction/Spectro/Spectro';
import Ultrasonic from '../../Pages/Transaction/Ultrasonic/Ultrasonic';
import Year_setting from '../../Pages/Master/Year_setting';

import AddBill from '../../Pages/Transaction/BillMaster/AddBill';
import AddDye from '../../Pages/Transaction/Dye/AddDye';
import AddEddy from '../../Pages/Transaction/Eddy/AddEddy';
import AddMagnetic from '../../Pages/Transaction/Magnetic/AddMagnetic';
import AddSpark from '../../Pages/Transaction/Spark/AddSpark';
import AddUltrasonic from '../../Pages/Transaction/Ultrasonic/AddUltrasonic';
import AddPMI from '../../Pages/Transaction/PMI/AddPMI';
import AddSpectro from '../../Pages/Transaction/Spectro/AddSpectro';
import Report from '../../Pages/Report';
import Dashboard from '../Layout/Dashboard';

const CustomRoutes = () => {
  return (
    <>
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
                   <Route path='/Dashboard' element={<Dashboard/>}/>
                    <Route path='/Db' element={<Db />} />
                    <Route path='/Material' element={<Material />} />
                    <Route path='/Customer' element={<Customer />} />
                    <Route path='/UT' element={<UT />} />
                    <Route path='/NEFT' element={<NEFT />} />
                    <Route path='/Element' element={<Element />} />
                    <Route path='/Branch' element={<Branch />} />
                    <Route path='/Technique' element={<Technique />} />
                    <Route path='/Instrument' element={<Instrument />} />
                    <Route path='/Probe' element={<Probe />} />
                    <Route path='/Year_setting' element={<Year_setting />} />
                    <Route path='/BillMaster' element={<BillMaster />} />
                    <Route path='/Dye' element={<Dye />} />
                    <Route path='/Eddy' element={<Eddy />} />
                    <Route path='/Magnetic' element={<Magnetic />} />
                    <Route path='/Spark' element={<Spark />} />
                    <Route path='/Ultrasonic' element={<Ultrasonic />} />
                    <Route path='/PMI' element={<PMI />} />
                    {/* <Route path='/pages/Master/PMI1' element={<PMI1 />} /> */}
                    <Route path='/Spectro' element={<Spectro />} />
                    <Route path='/Report' element={<Report />} />
                    
                    <Route path="/AddBill" element={<AddBill />} />
                    <Route  path="/EditBill/:billId" element={<AddBill />} />

                    <Route path="/AddDye" element={<AddDye />} />
                    <Route  path="/EditDye/:dyeId" element={<AddDye />} />

                    <Route path="/AddEddy" element={<AddEddy />} />
                    <Route  path="/EditEddy/:eddyId" element={<AddEddy />} />

                    <Route path="/AddMagnetic" element={<AddMagnetic />} />
                    <Route  path="/EditMagnetic/:magneticId" element={<AddMagnetic />} />

                    <Route path="/AddPMI" element={<AddPMI />} />
                    <Route  path="/EditPMI/:PMIId" element={<AddPMI />} />

                    <Route path="/AddSpark" element={<AddSpark />} />
                    <Route  path="/EditSpark/:sparkId" element={<AddSpark />} />

                    <Route path="/AddSpectro" element={<AddSpectro />} />
                    <Route  path="/EditSpectro/:spectroId" element={<AddSpectro />} />

                    <Route path="/AddUltrasonic" element={<AddUltrasonic />} />
                    <Route  path="/EditUltrasonic/:ultraId" element={<AddUltrasonic />} />

                    
                    
                  
      </Routes>
      </Suspense>
    
    </>
  )
}

export default CustomRoutes