import axios from 'axios';
import { PROBE_BASE_PATH } from './api-base-paths';

export const getAllProb = async () => {
    try {
        const response = await axios.get(`${PROBE_BASE_PATH}/getAllProb`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all prob:', error);
        throw error;
    }
};

export const createProb = async (probData) => {
    try {
        const response = await axios.post(`${PROBE_BASE_PATH}/createProb`, probData);
        return response.data;
    } catch (error) {
        console.error('Error creating prob:', error);
        throw error;
    }
};

export const deleteProb = async (probId) => {
    try {
        const response = await axios.delete(`${PROBE_BASE_PATH}/deleteProb/${probId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting prob:', error);
        throw error;
    }
};

export const updateProb = async (probId, probData) => {
    try {
        const response = await axios.put(`${PROBE_BASE_PATH}/updateProb/${probId}`, probData);
        return response.data;
    } catch (error) {
        console.error('Error updating prob:', error);
        throw error;
    }
};

export const getProbById = async (probId) => {
    try {
        const response = await axios.get(`${PROBE_BASE_PATH}/getProbById/${probId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching prob by ID:', error);
        throw error;
    }
};