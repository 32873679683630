import React ,{useState,useEffect} from 'react'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import dayjs from "dayjs";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import {Autocomplete, IconButton, MenuItem} from '@mui/material';
import { Select,  InputLabel , FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useNavigate } from 'react-router-dom';
import { getAllCustomersAuto } from '../../../lib/api-customer';
import {getUltrasonicById,updateUltrasonic,createUltrasonic, getAutoCertificateNo, deleteUltrasonicDescription, getPrint, getUltrasonicPDF} from '../../../lib/api-ultrasonic'
import DeleteIcon from '@mui/icons-material/Delete';
import { getAllEquipment, getAllStandards } from '../../../lib/api-ut';
import { DataGrid } from '@mui/x-data-grid';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import {Dialog, DialogActions, DialogTitle} from '@mui/material';
import { APP_BASE_PATH } from "../../../lib/api-base-paths";
import CustomSnackbar from "../../../components/common/CustomSnackbar";
import { getAllProb } from '../../../lib/api-probe';



const CreateUltrasonic = ({ultraId,isEdit}) => {
    const[cust,setCust]=useState([]);
    const[stand,setStand]=useState([]);
    const[stand1,setStand1]=useState([]);
    const[equip,setEquip]=useState([]);
    const[prob,setProb]=useState([]);
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [color,setColor]=useState("")
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [certificate_num, setCertificate_num] = useState(null);
    const [loading, setLoading] = useState(false);
    const [latestId,setLatestId]=useState(false)
    const [openPDFModal, setOpenPDFModal] = useState(false);
    const [ pdfData, setPdfData] = useState(null);
    const [ultradate, setUltradate] = useState(() => {
          const today = new Date();
          return today.toISOString().split('T')[0];
        });
    const handlePDFModalClose = ()=>{
      setPdfData(null)
      setOpenPDFModal(false)};
      const navigate = useNavigate();
      const handleBack=() =>
      {
      navigate('/Ultrasonic')
      }

      const [rows, setRows] = useState([]);
  const [formikValues, setFormikValues] = useState({
    Description: '',
    Qty: '',
    Weight: '',
    Accepted: '',
    
    Rejected: '',
    Remarks: '',
    customRemarks: ''
  });
  const [idCounter, setIdCounter] = useState(1);
 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormikValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddRow = () => {
 
    if (!formikValues.Description || !formikValues.Qty || !formikValues.Weight || !formikValues.Accepted || !formikValues.Rejected || !formikValues.Remarks) {
        alert("Please fill in all required fields.");
        return; 
    }
  
    const newRow = { id: idCounter, srNo: idCounter, ...formikValues };
    setIdCounter(idCounter + 1);
  
    if (rows.length > 0) {
        const maxSrNo = Math.max(...rows.map(row => row.srNo));
        newRow.srNo = maxSrNo + 1;
    } else {
        newRow.srNo = 1;
    }
  
    newRow.id = newRow.srNo;
  
    newRow.Remarks = formikValues.Remarks === 'Other' ? formikValues.customRemarks : formikValues.Remarks;
  
    setRows(prevRows => [...prevRows, newRow]);
  
    setFormikValues({
        Description: '',
        Qty: '',
        Weight: '',
        Accepted: '',
        Rejected: '',
        Remarks: '',
        customRemarks: ''
    });
  };
  



const handleDelete = (id) => {
  deleteUltrasonicDescription(id)
      .then(response => {
          console.log(response.data); 
          setRows(prevRows => prevRows.filter(row => row.id !== id));
      })
      .catch(error => {
          console.error('Error deleting row:', error);
      });
};


      const initialValues = {
        Certificate_No:'',
        Cert_Date:new Date(),
        Cust_Name:{value:'',label:''},
        Referance_Id:'',
        Exam_Stage:'',
        Acceptance_Std:{value:'',label:''},
        Technique:'',
        Equipment:{value:'',label:''},
        Standard:{value:'',label:''},
        Range1:'',
        Gain:'',
        Techniquel:'',
        Probes_Use:{value:'',label:''},
        Calibration_Block:'',
        Couplnat:'',
        Location:'',
        Witness_By:'',
        technician:'',
        designation:'ASNT LEVEL II - UT.MT.PT',
        customExamStage:'',
        customRange:'',
        customGain:'',
      }

      const columns = [
        { field: 'srNo', headerName: 'Sr.No.', flex: 1 },
        { field: 'Description', headerName: 'Part Description', flex: 1 },
        { field: 'Qty', headerName: 'Qty', flex: 1 },
        { field: 'Weight', headerName: 'Weight', flex: 1 },
        { field: 'Accepted', headerName: 'Accepted', flex: 1 },
        { field: 'Rejected', headerName: 'Rejected', flex: 1 },
        { field: 'Remarks', headerName: 'Remarks', flex: 1 },
        {
          field: 'action',
          headerName: 'Action',
          flex: 1,
          renderCell: (params) => (
            <div>
            <IconButton   onClick={()=> handleDelete(params.row.id) }>
              <DeleteIcon sx={{ color: 'red' }} />
            </IconButton>
          </div>
          
          ),
        },
      ];


      useEffect(()=>{
        const fetchData = async () => {
          try {
            const response = await getAllCustomersAuto();
            setCust(() => {
              const customerOp = response.map((row) => { return { value: row.Cust_Id, label: row.Cust_Name } });
              return customerOp; 
          });
          } catch (error) {
            console.error("Error fetching customers:", error);
          }
        };
        fetchData();
      },[])


      useEffect(()=>{
        const fetchData = async () => {
          try {
            const response = await getAllEquipment();
            const EquipmentOp = response.map((row) => { return { value: row.Equipment_Id, label: row.Equipment_Nm } });
            setEquip(EquipmentOp);
          } catch (error) {
            console.error("Error fetching Equipment:", error);
          }
        };
        fetchData();
      },[])

      useEffect(()=>{
        const fetchData = async () => {
          try {
            const response = await getAllProb();
            const ProbOp = response.map((row) => { return { value: row.Prob_Id, label: row.Prob_Nm } });
            setProb(ProbOp);
          } catch (error) {
            console.error("Error fetching Prob:", error);
          }
        };
        fetchData();
      },[])


      useEffect(()=>{
        const fetchData = async () => {
          try {
            const response = await getAllStandards();
            const Acceptance_StdOp = response.map((row) => { return { value: row.Standard_Id, label: row.Acceptance_Std } });
            setStand(Acceptance_StdOp);
            setStand1(Acceptance_StdOp);

          } catch (error) {
            console.error("Error fetching Acceptance_Std:", error);
          }
        };
        fetchData();
      },[])


      useEffect(() => {
        const fetchCertificateNo = async () => {
          try {
            const getCertificateNo = await getAutoCertificateNo(ultradate);
            const maxNumber = getCertificateNo.data.nextCertificateNo;
            formik.setFieldValue('Certificate_No' , maxNumber) ;
            setCertificate_num(maxNumber);
          } catch (error) {
            
          }
        }
        fetchCertificateNo();
      },[ultradate])

      useEffect(() => {
        const fetchData = async () =>
        {
            try {
              setLoading(true);
                const getUltrasonicData = await getUltrasonicById(ultraId);
                setLatestId(true);                
                const UltrasonicData = getUltrasonicData.data.ultrasonic;
                  
                  

                const parseDate = (dateString) => {
                    if(dateString)
                    {
                        const parts = dateString.split('/');
                        return `${parts[2]}-${parts[1]}-${parts[0]}`;
                    }
                    return null;
                }
                const EquipNew = equip.filter((item)=>item.value ==  UltrasonicData.Equipment)[0];
                const StandNew = stand.filter((item)=>item.value ==  UltrasonicData.Acceptance_Std)[0];
                const StandNew1 = stand1.filter((item)=>item.value ==  UltrasonicData.Standard)[0];
                const ProbNew = prob.filter((item)=>item.value ==  UltrasonicData.Probes_Use)[0];
                const CustNew = cust.filter((item)=>item.value ==  UltrasonicData.Cust_Name)[0];
                const sortedData = {
                Cert_Date: parseDate(UltrasonicData.Cert_Date),
                Cust_Name:CustNew,
                Certificate_No: UltrasonicData.Certificate_No,
                Referance_Id:UltrasonicData.Referance_Id,
                Exam_Stage: UltrasonicData.Exam_Stage ,
                Acceptance_Std:StandNew,
                Technique:UltrasonicData.Technique,
                Equipment:EquipNew,
                Standard:StandNew1,
                Range1:UltrasonicData.Range1 ,
                Gain:UltrasonicData.Gain ,
                Techniquel:UltrasonicData.Techniquel,
                Probes_Use:ProbNew,
                Calibration_Block:UltrasonicData.Calibration_Block,
                Couplnat:UltrasonicData.Couplnat,
                Location:UltrasonicData.Location,
                Witness_By:UltrasonicData.Witness_By,
                technician:UltrasonicData.technician,
                designation:UltrasonicData.designation,
                customExamStage: UltrasonicData.customExamStage,
                customRange: UltrasonicData.customRange,
                customGain: UltrasonicData.customGain,

                };
                formik.setValues(sortedData);
              
                const sortedRows = getUltrasonicData.data.rows.map((row, index) => ({
                  id: row.id,
                  srNo: index + 1,
                  ...row
              }));
              setRows(sortedRows);
              setLoading(false);
             
            } catch (error) {
                console.log("Error in Fetching Ultrasonic Data",error);    
            }finally{
              setLoading(false);
            }
        };
        if(isEdit)
        {
            fetchData();
        }
     }, [isEdit,ultraId,cust,equip,stand,stand1,prob]);

     const formik = useFormik({
      initialValues:initialValues,
      validationSchema:Yup.object().shape({
          Cert_Date:Yup.date().required(),
          Certificate_No:Yup.number().required(),
          Cust_Name:Yup.object().notRequired(),
          Referance_Id:Yup.string().required(),
          Exam_Stage:Yup.string().required(),
          Acceptance_Std:Yup.object().required(),
          Technique:Yup.string().required(),
          Standard:Yup.object().required(),
          Equipment:Yup.object().required(),
          Range1:Yup.string().required(),
          Gain:Yup.string().required(),
          Techniquel:Yup.string().required(),
          Probes_Use:Yup.object().notRequired(),
          Calibration_Block:Yup.string().required(),
          Couplnat:Yup.string().required(),
          Location:Yup.string().required(),
          Witness_By:Yup.string().required(),
          technician:Yup.string().required(),
          designation:Yup.string().required(),
          
      }),
      onSubmit: async(values, { setErrors, setStatus, setSubmitting, resetForm }) => {
          try {
            setLoading(true);
            let response = {};

            values.rows = rows;

        
            if (isEdit) {
              response = await updateUltrasonic(ultraId, values);
              setConfirmationMessage("Ultrasonic Updated successfully");
              setIsConfirmationOpen(true);
              setColor('green')            } else {
              response = await createUltrasonic(values);
              if(response.status==200){
                setConfirmationMessage(response.data);
              setIsConfirmationOpen(true);
              setColor('green')
                setLatestId(true);
                resetForm();
                 
                setRows([]);}
                else{
                  setConfirmationMessage("Somthing Went Wrong!");
              setIsConfirmationOpen(true);
              setColor('error')
                }
            }
          } catch (error) {
            setStatus({ success: false });
            setErrors({ submit: error.response.data.message });
            setSubmitting(false);
          }finally{
            setLoading(false);
          }
        }
        
    });

    const handlep=()=>{
      if(ultraId){
        handlePrintforedit()
      }else{
        handlePrint()
      }
    }

    const handlePrintforedit = async()=>{
      try {
        setLoading(true);
        const getPDF = await getUltrasonicPDF(ultraId);
        setOpenPDFModal(true);
        const filepath=getPDF.data.returnpath
        setPdfData(filepath);
        
      } catch (error) {
        console.error("Error while printing:", error);
      }finally{
        setLoading(false);
    
      }
    }
    const handlePrint= async()=>{
  
      try {
        setLoading(true)
        const response = await getPrint();
        
        setOpenPDFModal(true);
        const filepath=response.returnpath
      
        setPdfData(filepath);
      } catch (error) {
        console.error("Error fetching customers:", error);
      }finally{
        setLoading(false)
      }
  
  }
  

  
   

  return (
    
    <div>
      {loading && <LoadingSpinner/>}
        <div style={{display:"flex", flexDirection:"column",gap:"1rem"}}>
        <div style={{ display: 'flex', alignItems: 'center', gap:"0.5rem" }}>
              <FormControl style={{width:"180px"}}><LocalizationProvider dateAdapter={AdapterDayjs}> <DatePicker name="Cert_Date" format="DD/MM/YYYY" label="Date"  errors={formik.errors}  
              value={formik.values.Cert_Date ? dayjs(formik.values.Cert_Date) : null}
              // onChange={(date) => {formik.setFieldValue('Cert_Date', date);}}
              onChange={(date) => {
                formik.setFieldValue(
                  "Cert_Date",
                  date ? dayjs(date).format('YYYY-MM-DD') : dayjs(formik.values.assignedDate).format('YYYY-MM-DD') || null
                );
                setUltradate(date ? dayjs(date).format('YYYY-MM-DD') : dayjs(formik.values.assignedDate).format('YYYY-MM-DD') || null)
              }} />  </LocalizationProvider></FormControl> 
                  <TextField style={{width:"37.5vw"}}   id="outlined-basic" label="Certificate No." name='Certificate_No' variant="outlined" error={formik.errors.Certificate_No && formik.touched.Certificate_No}
                      helperText={formik.touched.Certificate_No ? formik.errors.Certificate_No : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Certificate_No}/> 
              </div>
              <div>
              <Autocomplete
          options={cust}
          getOptionLabel={(option) => option.label}
          onChange={(event, value) => formik.setFieldValue('Cust_Name', value || null)}
          value={formik.values.Cust_Name}
          renderInput={(params) => (
            <TextField
            {...params}
            sx={{width:"50vw"}}
            label="Customer Name"
            variant="outlined"
            name="Cust_Name"
            error={formik.errors.Cust_Name && formik.touched.Cust_Name}
            helperText={formik.touched.Cust_Name ? formik.errors.Cust_Name : ''}
            onBlur={formik.handleBlur}
            />
          )}
        />
            
        </div>
        <div>
        <TextField style= {{width:"50vw"}}   id="outlined-basic" label="Reference" name='Referance_Id' variant="outlined" error={formik.errors.Referance_Id && formik.touched.Referance_Id}
                      helperText={formik.touched.Referance_Id ? formik.errors.Referance_Id : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Referance_Id} />
          
        </div>
        
        <div >
        <div>
      <div style={{ display: 'flex', alignItems: 'center', columnGap: '1%' }}>

      
      
        <TextField
          id="outlined-basic"
          label="Part Description"
          name="Description"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Description}
        />

        <TextField
          id="outlined-basic"
          label="Qty"
          name="Qty"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Qty}
        />

        <TextField
          id="outlined-basic"
          label="Weight"
          name="Weight"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Weight}
        />

        <TextField
          id="outlined-basic"
          label="Accepted"
          name="Accepted"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Accepted}
        />

        <TextField
          id="outlined-basic"
          label="Rejected"
          name="Rejected"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Rejected}
        />

        <FormControl>
          <InputLabel id="remarks-label">Remarks</InputLabel>
          <Select
            labelId="remarks-label"
            value={formikValues.Remarks}
            onChange={handleChange}
            label="Remarks"
            placeholder="Remarks"
            name="Remarks"
            style={{ width: '10vw' }}
          >
            <MenuItem value="No recordable Indication observed- Hence Accepted.">No recordable Indication observed- Hence Accepted.</MenuItem>
            <MenuItem value="Recordable indication observed hence not accepted.">Recordable indication observed hence not accepted.</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
            {formikValues.Remarks !== 'No recordable Indication observed- Hence Accepted.' &&
             formikValues.Remarks !== 'Recordable indication observed hence not accepted.' &&
             formikValues.Remarks !== 'Other' && (
    <MenuItem value={formikValues.Remarks}>
      {formikValues.Remarks}
    </MenuItem>
  )}
          </Select>
          {formikValues.Remarks === 'Other' && (
            <TextField
              name="customRemarks"
              value={formikValues.customRemarks}
              onChange={handleChange}
              label="Custom Remark"
              variant="outlined"
              style={{ marginTop: '20px', width: '10vw' }}
            />
          )}
        </FormControl>
        <Button onClick={handleAddRow} style={{ backgroundColor: "red", color: "white" }} variant="contained">Add</Button>


      </div>
      <div style={{ marginTop: "20px" }}>
        <DataGrid
          autoHeight
          density="compact"
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </div>
     
    </div>
        </div>

        </div>

       <div style={{display:"grid" , gridTemplateColumns:"1fr 1fr 1fr", gap:"2rem", marginTop:"3vh",marginBottom:"8vh"}}>
                  
<FormControl>
  <InputLabel id="Exam_Stage-label">Examination Stage</InputLabel>
  <Select
    labelId="Exam_Stage-label"
    value={formik.values.Exam_Stage}
    onChange={(event) => {
      formik.handleChange(event);
      if (event.target.value === 'Other') {
        formik.setFieldValue('customExamStage', ''); 
      }
    }}
    label="Examination Stage"
    name="Exam_Stage"
    error={formik.errors.Exam_Stage && formik.touched.Exam_Stage}
    onBlur={formik.handleBlur}
  >
    <MenuItem value="Raw">Raw</MenuItem>
    <MenuItem value="Finished">Finished</MenuItem>
    <MenuItem value="Semi-Finished">Semi-Finished</MenuItem>
    <MenuItem value="Other">Other</MenuItem>
    {formik.values.Exam_Stage !== 'Raw' &&
   formik.values.Exam_Stage !== 'Finished' &&
   formik.values.Exam_Stage !== 'Semi-Finished' &&
   formik.values.Exam_Stage !== 'Other' && (
    <MenuItem value={formik.values.Exam_Stage}>
      {formik.values.Exam_Stage}
    </MenuItem>
  )}
  </Select>
  {formik.values.Exam_Stage === 'Other' && (
    <TextField
      name="customExamStage"
      value={formik.values.customExamStage}
      onChange={formik.handleChange}
      label="Custom Exam Stage"
      variant="outlined"
      style={{ marginTop: "30px" }}
    />
  )}
  {formik.errors.Exam_Stage && formik.touched.Exam_Stage && (
    <FormHelperText error>
      {formik.errors.Exam_Stage}
    </FormHelperText>
  )}
</FormControl>


<Autocomplete
          options={stand}
          getOptionLabel={(option) => option.label}
          onChange={(event, value) => formik.setFieldValue('Acceptance_Std', value || null)}
          value={formik.values.Acceptance_Std}
          renderInput={(params) => (
            <TextField
            {...params}
           
            label="Acceptance Standard"
            variant="outlined"
            name="Acceptance_Std"
            error={formik.errors.Acceptance_Std && formik.touched.Acceptance_Std}
            helperText={formik.touched.Acceptance_Std ? formik.errors.Acceptance_Std : ''}
            onBlur={formik.handleBlur}
            />
          )}
        />     
       <FormControl>
     <InputLabel id="Technique">Technique</InputLabel>
      <Select
        labelId="Technique"
        value={formik.values.Technique}
        onChange={formik.handleChange}
        label="Technique"
        name="Technique"
        error={formik.errors.Technique && formik.touched.Technique}
        onBlur={formik.handleBlur}
      >
        <MenuItem value="Pulse Echo">Pulse Echo</MenuItem>
    
      </Select>
      {formik.errors.Technique && formik.touched.Technique  && (
    <FormHelperText error>
      {formik.errors.Technique}
    </FormHelperText>
  )}
      </FormControl>
                  

<Autocomplete
          options={equip}
          getOptionLabel={(option) => option.label}
          onChange={(event, value) => formik.setFieldValue('Equipment', value || null)}
          value={formik.values.Equipment}
          renderInput={(params) => (
            <TextField
            {...params}
          
            label="Equipment"
            variant="outlined"
            name="Equipment"
            error={formik.errors.Equipment && formik.touched.Equipment}
            helperText={formik.touched.Equipment ? formik.errors.Equipment : ''}
            onBlur={formik.handleBlur}
            />
          )}
        />

<Autocomplete
          options={stand1}
          getOptionLabel={(option) => option.label}
          onChange={(event, value) => formik.setFieldValue('Standard', value || null)}
          value={formik.values.Standard}
          renderInput={(params) => (
            <TextField
            {...params}
           
            label="Standard/Ut Procedure"
            variant="outlined"
            name="Standard"
            error={formik.errors.Standard && formik.touched.Standard}
            helperText={formik.touched.Standard ? formik.errors.Standard : ''}
            onBlur={formik.handleBlur}
            />
          )}
        />     

<FormControl>
  <InputLabel id="Range-label">Range</InputLabel>
  <Select
    labelId="Range-label"
    value={formik.values.Range1}
    onChange={(event) => {
      formik.handleChange(event);
      if (event.target.value === 'Other') {
        formik.setFieldValue('customRange', '');
      }
    }}
    label="Range"
    name="Range1"
    error={formik.errors.Range1 && formik.touched.Range1}
    onBlur={formik.handleBlur}
  >
    <MenuItem value="0-100mm">0-100mm</MenuItem>
    <MenuItem value="0-200mm">0-200mm</MenuItem>
    <MenuItem value="0-300mm">0-300mm</MenuItem>
    <MenuItem value="0-400mm">0-400mm</MenuItem>
    <MenuItem value="0-500mm">0-500mm</MenuItem>
    <MenuItem value="Other">Other</MenuItem>
    {formik.values.Range1 !== '0-100mm' &&
   formik.values.Range1 !== '0-200mm' &&
   formik.values.Range1 !== '0-300mm' &&
   formik.values.Range1 !== '0-400mm' &&
   formik.values.Range1 !== '0-500mm' &&
   formik.values.Range1 !== 'Other' && (
    <MenuItem value={formik.values.Range1}>
      {formik.values.Range1}
    </MenuItem>
  )}
  </Select>
  {formik.values.Range1 === 'Other' && (
    <TextField
      name="customRange"
      value={formik.values.customRange}
      onChange={formik.handleChange}
      label="Custom Range"
      variant="outlined"
      style={{marginTop:"30px"}}
    />
  )}
  {formik.errors.Range1 && formik.touched.Range1 && (
    <FormHelperText error>
      {formik.errors.Range1}
    </FormHelperText>
  )}
</FormControl>


      <FormControl>
     <InputLabel id="Gain-label">Gain</InputLabel>
      <Select
         labelId="Gain-label"
        value={formik.values.Gain}
        onChange={(event) => {
          formik.handleChange(event);
          if (event.target.value === 'Other') {
            formik.setFieldValue('customGain', ''); 
          }
        }}
        label="Gain"
        name="Gain"
        error={formik.errors.Gain && formik.touched.Gain}
        onBlur={formik.handleBlur}
      >
        <MenuItem value="100% B.W. +6dB">100% B.W. +6dB</MenuItem>
        <MenuItem value="Below 80% Backwall Echo & Defect 20%">Below 80% Backwall Echo & Defect 20%</MenuItem>
        <MenuItem value="Other">Other</MenuItem>
        {formik.values.Gain !== '100% B.W. +6dB' &&
   formik.values.Gain !== 'Below 80% Backwall Echo & Defect 20%' && 
   formik.values.Gain !== 'Other' && (
    <MenuItem value={formik.values.Gain}>
      {formik.values.Gain}
    </MenuItem>
   )}
      </Select>
      {formik.values.Gain === 'Other' && (
        <TextField
          name="customGain"
          value={formik.values.customGain}
          onChange={formik.handleChange}
          label="Custom Gain"
          variant="outlined"
          style={{marginTop:"30px"}}
        />
      )}
      {formik.errors.Gain && formik.touched.Gain && (
    <FormHelperText error>
      {formik.errors.Gain}
    </FormHelperText>
  )}
      </FormControl>


                  
                  
      



      <FormControl>
     <InputLabel id="techniquel">Technique</InputLabel>
      <Select
        labelId="techniquel"
        value={formik.values.Techniquel}
        onChange={formik.handleChange}
        label="Technique"
        name="Techniquel"
        error={formik.errors.Techniquel && formik.touched.Techniquel}
        onBlur={formik.handleBlur}
      >
        <MenuItem value="Normal">Normal</MenuItem>
        <MenuItem value="Angular">Angular</MenuItem>
      </Select>
      {formik.errors.Techniquel && formik.touched.Techniquel  && (
    <FormHelperText error>
      {formik.errors.Techniquel}
    </FormHelperText>
  )}
      </FormControl>        
      
       {/* <FormControl>
     <InputLabel id="probs-used">Probs Used</InputLabel>
      <Select
        labelId="probs-used"
        value={formik.values.Probes_Use}
        onChange={formik.handleChange}
        label="Probs Used"
        name="Probes_Use"
        error={formik.errors.Probes_Use && formik.touched.Probes_Use}
        onBlur={formik.handleBlur}
      >
        <MenuItem value={'10mm Dia"X4mhz'}>10mm Dia"X4mhz</MenuItem>
        <MenuItem value={'10mm Dia 2mhz'}>10mm Dia 2mhz</MenuItem>
        <MenuItem value={'24 MM DIA "X2MHZ'}>24 MM DIA "X2MHZ</MenuItem>
        <MenuItem value={'8X9MMX70X4MHZ'}>8X9MMX70X4MHZ</MenuItem>
        <MenuItem value={'TR. 10MM DIA X4MHZ'}>TR. 10MM DIA X 4MHZ</MenuItem>
        <MenuItem value={'10mm Dia x 6MHZ'}>10mm Dia x 6MHZ</MenuItem>
        <MenuItem value={'Tr. 10mm Dia x 6MHZ'}>Tr. 10mm Dia x 6MHZ</MenuItem>
        <MenuItem value={'Tr. 10mm Dia x 2MHZ'}>Tr. 10mm Dia x 2MHZ</MenuItem>
      </Select>
      {formik.errors.Probes_Use && formik.touched.Probes_Use  && (
    <FormHelperText error>
      {formik.errors.Probes_Use}
    </FormHelperText>
  )}
      </FormControl> */}

<Autocomplete
          options={prob}
          getOptionLabel={(option) => option.label}
          onChange={(event, value) => formik.setFieldValue('Probes_Use', value || null)}
          value={formik.values.Probes_Use}
          renderInput={(params) => (
            <TextField
            {...params}
          
            label="Probes Use"
            variant="outlined"
            name="Probes_Use"
            error={formik.errors.Probes_Use && formik.touched.Probes_Use}
            helperText={formik.touched.Probes_Use ? formik.errors.Probes_Use : ''}
            onBlur={formik.handleBlur}
            />
          )}
        />

      <FormControl>
     <InputLabel id="calibration-block">Calibration Block</InputLabel>
      <Select
        labelId="calibration-block"
        value={formik.values.Calibration_Block}
        onChange={formik.handleChange}
        label="Calibration Block"
        name="Calibration_Block"
        error={formik.errors.Calibration_Block && formik.touched.Calibration_Block}
        onBlur={formik.handleBlur}
      >
        <MenuItem value="V1">V1</MenuItem>
        <MenuItem value="V2">V2</MenuItem>
      </Select>
      {formik.errors.Calibration_Block && formik.touched.Calibration_Block  && (
    <FormHelperText error>
      {formik.errors.Calibration_Block}
    </FormHelperText>
  )}
      </FormControl>



      <FormControl>
     <InputLabel id="Couplnat-label">Couplnat</InputLabel>
      <Select
        labelId="Couplnat-label"
        value={formik.values.Couplnat}
        onChange={formik.handleChange}
        label="Couplnat"
        name="Couplnat"
        error={formik.errors.Couplnat && formik.touched.Couplnat}
        onBlur={formik.handleBlur}
      >
        <MenuItem value="Oil">Oil</MenuItem>
        <MenuItem value="Water">Water</MenuItem>
      </Select>
      {formik.errors.Couplnat && formik.touched.Couplnat  && (
    <FormHelperText error>
      {formik.errors.Couplnat}
    </FormHelperText>
  )}
      </FormControl>

                        <TextField   id="outlined-basic" label="Location" name='Location' variant="outlined" 
                        error={formik.errors.Location && formik.touched.Location}
                        helperText={formik.touched.Location ? formik.errors.Location : ''}
                         onBlur={formik.handleBlur}
                       onChange={formik.handleChange}
                       value={formik.values.Location} />

                        <TextField   id="outlined-basic" label="Witness By" name='Witness_By' variant="outlined" error={formik.errors.Witness_By && formik.touched.Witness_By}
                      helperText={formik.touched.Witness_By ? formik.errors.Witness_By : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Witness_By}/>

                        <TextField   id="outlined-basic" label="Technician" name='technician' variant="outlined" 
                        error={formik.errors.technician && formik.touched.technician}
                        helperText={formik.touched.technician ? formik.errors.technician : ''}
                         onBlur={formik.handleBlur}
                       onChange={formik.handleChange}
                       value={formik.values.technician} />

                      <TextField   id="outlined-basic" label="Designation" name='designation' variant="outlined" 
                        error={formik.errors.designation && formik.touched.designation}
                        helperText={formik.touched.designation ? formik.errors.designation : ''}
                         onBlur={formik.handleBlur}
                       onChange={formik.handleChange}
                       value={formik.values.designation} />


                  <div style={{display:"flex",gap:"3rem"}}>
                        
                  </div>
               </div>
               <div style={{display:"flex", justifyContent:"flex-end",}}>
                  <Button onClick={handlep} style={{ backgroundColor: latestId ? "orange" : "gray", color: "white", marginInline: "0.7rem", opacity: latestId ? 1 : 0.5 }} disabled={!latestId}>PRINT</Button>

                  <Button
                  onClick={formik.handleSubmit}
                  style={{
                  backgroundColor: "Green",
                  color: "white", 
                  marginInline: "0.7rem", 
                 
                  }}
                  
                  >
                  {ultraId === undefined ? "Save" : "Update"}
                  </Button>

                  <Button onClick ={handleBack} style={{backgroundColor:"red",color:"white", marginInline:"0.7rem"}}>CANCEL</Button>
                  <Button onClick ={handleBack} style={{backgroundColor:"blue",color:"white"}}>BACK</Button>
                </div>

                <Dialog
        open={openPDFModal}
        onClose={handlePDFModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
        maxWidth={1200}
      >
        <DialogTitle>
          Invoice
        </DialogTitle>
        
        <iframe title="pdf-view"   src={pdfData && pdfData ? `${APP_BASE_PATH}${pdfData}` : null }width="800px" height="800px"></iframe>
      
        <DialogActions>
          <Button onClick={handlePDFModalClose}>Close</Button>          
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={isConfirmationOpen}
        message={confirmationMessage}
      color={color}
        onClose={() => setIsConfirmationOpen(false)}
      /> 
    </div>
    
  )
}

export default CreateUltrasonic;
