import React,{useEffect, useState} from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DashboardIcon from '@mui/icons-material/Dashboard';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import {Link} from 'react-router-dom';
import GradingIcon from '@mui/icons-material/Grading';
import TextSnippetSharpIcon from '@mui/icons-material/TextSnippetSharp';
import NoteIcon from '@mui/icons-material/Note';
import List from '@mui/material/List';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Collapse from '@mui/material/Collapse';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import RepeatIcon from '@mui/icons-material/Repeat';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AdjustIcon from '@mui/icons-material/Adjust';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import { SlPaperClip } from "react-icons/sl";
const ListItems = (props) => {
  const [open, setOpen] = useState(false);
  // const [close, setClose] = useState(false);

  
  const [open1, setOpen1] = useState(false);

  const handleClick = () => {
    setOpen(!open);
    setOpen1(false);
  };

  const handleClick1 = () => {
    setOpen1(!open1);
    setOpen(false);
    
  };
  // const handleclose=()=>{
  //  setClose(true);

  // }
 
  // useEffect(()=>{
  //   if(props.openparent == false){
  //     setOpen(false);
  //     console.log("open-",open)
  //     setOpen1(false);
  //     console.log("open-",open1)
  //   }
  //   // if(close == true){
  //   //   props.setOpenparent(false);
      
  //   // }
    
  // })

  
    return (
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper',backgroundColor: "#3d3d3d", color:"white !important", }}
        component="nav"
        aria-labelledby="nested-list-subheader"
       
      >
        <Link to='/Db' style={{textDecoration: "none",color:"white"}} >
          <ListItemButton >
            <ListItemIcon>
                <DashboardIcon sx={{color:'white'}} />
            </ListItemIcon>
            
            <ListItemText primary="DASHBOARD" />
          </ListItemButton>
        </Link>


        <ListItemButton onClick={handleClick} >
          <ListItemIcon>
            <HandshakeIcon sx={{color:'white'}} />
          </ListItemIcon>
          <ListItemText primary="MASTER" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List  component="div" disablePadding>
            <Link  to='/Material' style={{textDecoration: "none",color:"white"}} >
              <ListItemButton  sx={{ pl: 4 }}>
              <ListItemIcon>
            <AdjustIcon sx={{color:'white'}} />
            </ListItemIcon>
                <ListItemText primary=" Material" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link to='/Customer' style={{textDecoration: "none",color:"white"}} >
            <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
            <PeopleAltIcon style={{color:'white'}} />
            </ListItemIcon>
              <ListItemText primary=" Customer" />
                
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link to='/UT' style={{textDecoration: "none",color:"white"}} >
            <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
            <AddCircleIcon style={{color:'white'}} />
            </ListItemIcon>
              <ListItemText primary=" UT" />
                
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <Link to='/NEFT' style={{textDecoration: "none",color:"white"}} >
              <ListItemButton  sx={{ pl: 4 }}>
              <ListItemIcon>
            <AccountBalanceIcon style={{color:'white'}} />
            </ListItemIcon>
                <ListItemText primary=" NEFT Bank" />
                
                
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <Link to='/Element' style={{textDecoration: "none",color:"white"}} >
              <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
            <GradingIcon style={{color:'white'}} />
            </ListItemIcon>
                  <ListItemText primary=" Element" />
                
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <Link to='/Branch' style={{textDecoration: "none",color:"white"}} >
            
              <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
            <NoteIcon sx={{color:'white'}} />
            </ListItemIcon>
                <ListItemText primary=" Branch" />
                
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <Link to='/Technique' style={{textDecoration: "none",color:"white"}} >
            <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
            <ContentPasteIcon sx={{color:'white'}} />
            </ListItemIcon>
                <ListItemText primary=" Technique" />
              
            </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <Link to='/Instrument' style={{textDecoration: "none",color:"white"}} >
            <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
            <InventoryIcon sx={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary=" Instrument" style={{fontFamily:"serif"}} />
              
            </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <Link to='/Probe' style={{textDecoration: "none",color:"white"}} >
            <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
            <AllInboxIcon sx={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary=" Probe" style={{fontFamily:"serif"}} />
              
            </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <Link to='/Year_setting' style={{textDecoration: "none",color:"white"}} >
            <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
            <AllInboxIcon sx={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary=" Year Setting" style={{fontFamily:"serif"}} />
              
            </ListItemButton>
            </Link>
          </List>
        </Collapse>
        
        
        <ListItemButton onClick={handleClick1}>
          <ListItemIcon>
            <RepeatIcon sx={{color:'white'}}  />
          </ListItemIcon>
          <ListItemText primary="TRANSACTIONS" />
          {open1 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <Link to='/BillMaster' style={{textDecoration: "none",color:"white"}} >
            <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
            <ReceiptLongIcon style={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary="Bill Master" />
              
            </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Collapse in={open1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <Link to='/Dye' style={{textDecoration: "none",color:"white"}} >
            <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
            <AlignHorizontalLeftIcon style={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary=" Dye" />
              
            </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Collapse in={open1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <Link to='/Eddy' style={{textDecoration: "none",color:"white"}} >
            <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
            <AlignHorizontalCenterIcon style={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary=" Eddy" />
              
            </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Collapse in={open1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <Link to='/Magnetic' style={{textDecoration: "none",color:"white"}} >
            <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
            <AlignHorizontalRightIcon style={{color:'white'}} />
            </ListItemIcon>
              <ListItemText primary=" Mangnetic" />
              
            </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Collapse in={open1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <Link to='/Spark' style={{textDecoration: "none",color:"white"}} >
            <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
            <AlignVerticalBottomIcon style={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary=" Spark" />
              
            </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Collapse in={open1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <Link to='/Ultrasonic' style={{textDecoration: "none",color:"white"}} >
            <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
            <AlignVerticalTopIcon style={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary=" Ultrasonic" />
              
            </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Collapse in={open1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <Link to='/PMI' style={{textDecoration: "none",color:"white"}} >
            <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
            <AlignVerticalCenterIcon style={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary=" PMI" />
              
            </ListItemButton>
            </Link>
          </List>
        </Collapse>
        {/* <Collapse in={open1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <Link to='/pages/Master/PMI1' style={{textDecoration: "none",color:"white"}} >
            <ListItemButton sx={{ pl: 4 }}>
            <ListItemText primary="- PMI1" />
              
            </ListItemButton>
            </Link>
          </List>
        </Collapse> */}
        <Collapse in={open1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <Link to='/Spectro' style={{textDecoration: "none",color:"white"}} >
            <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
            <SlPaperClip style={{color:'white'}} />
            </ListItemIcon>
           <ListItemText primary=" Spectro" />
            </ListItemButton>
            </Link>
          </List>
        </Collapse>


        <Link to='/Report' style={{textDecoration: "none",color:"white"}} >
            <ListItemButton>
                <ListItemIcon>
                  <TextSnippetSharpIcon sx={{color:'white'}} />
                </ListItemIcon>
                <ListItemText primary="REPORT"/>
            </ListItemButton>
        </Link>
      </List>
    );
  
}

export default ListItems;