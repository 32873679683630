import React, { useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import dayjs from "dayjs";

import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import DeleteIcon from '@mui/icons-material/Delete';
import {Autocomplete, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers';

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useNavigate } from 'react-router-dom';
import { getAllCustomer } from '../../../lib/api-customer';
import { getBillById ,updateBill,addBill, getAutoBill_no, getCertificateNo, getDescriptionDataByCertificateNo, getPrint, getBillMastPDF, getReferance_Id} from '../../../lib/api-bill';
import { getAllNeft_bank } from '../../../lib/api-neft_bank';
import { DataGrid } from '@mui/x-data-grid';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import {Dialog, DialogActions, DialogTitle} from '@mui/material';
import { APP_BASE_PATH } from "../../../lib/api-base-paths";
import CustomSnackbar from "../../../components/common/CustomSnackbar";



function CreateBill({billId,isEdit}) {
   
    const navigate = useNavigate();
    const handleBack= ()=>{
        navigate('/BillMaster')
    }
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [color,setColor]=useState("")
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const[cust,setCust]= useState([]);
    const[bank,setBank]= useState([]);
    const [certificateNos, setCertificateNos] = useState([]);
    const [referenceIds, setReferenceIds] = useState('');
    const [loading, setLoading] = useState(false);
    const [latestId,setLatestId]=useState(false)
    const [openPDFModal, setOpenPDFModal] = useState(false);
    const [ pdfData, setPdfData] = useState(null);
    const handlePDFModalClose = ()=>{
      setPdfData(null)
      setOpenPDFModal(false)};

    const [selectedValue, setSelectedValue] = useState('');
    const handleRadio =(e)=>
    {
      
       setSelectedValue(e.target.value);
       handleRadioButton(e.target.value);
    };
    const[neftCheck,setNeftCheck] = useState(false);
    const neftChecker= () =>
    {
        setNeftCheck((prev) => !prev)
    }
   useEffect(()=>{
     if(isEdit)
     {
      setNeftCheck(true);
     }
   },[neftCheck]);
  // console.log(isEdit);
  // console.log(billId);
  
  const [if_igst, setIf_igst] = useState('');
    const[gstCheck,setGstCheck] = useState(false);
    const gstChecker= (event) =>
    {
      const isChecked = event.target.checked;
      setGstCheck(isChecked);
      if(isChecked){
        setIf_igst('checked');
      }else{
        setIf_igst('');
      }
        // setGstCheck((prev) => !prev)
    };
    const [inpoint, setInpoint] = useState('roundup');
    const [roundup, setRoundup] = useState(false);
   

   
    const [pmiFlag, setPmiFlag] = useState('');
    const [visitChecked, setVisitChecked] = useState(false);
    const [hoursChecked, setHoursChecked] = useState(false);
  
    const handleHoursChange = (event) => {
      const isChecked = event.target.checked;
      setHoursChecked(isChecked);
      if (isChecked) {
        setPmiFlag('Hours'); // Update pmiFlag directly with the value
      } else {
        setPmiFlag(''); // Set pmiFlag to an empty string
      }
    };
    
    const handleVisitChange = (event) => {
      const isChecked = event.target.checked;
      setVisitChecked(isChecked);
      if (isChecked) {
        setPmiFlag('Visit'); // Update pmiFlag directly with the value
      } else {
        setPmiFlag(''); // Set pmiFlag to an empty string
      }
    };
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [checkedCertificateNos, setCheckedCertificateNos] = useState([]);
    const [rows, setRows] = useState([]);
    const [sumOfQuantity, setSumOfQuantity] = useState(0);
    const [unitRateMap, setUnitRateMap] = useState({});
    const [defaultRow, setDefaultRow] = useState(0);
    const [bill_date,setBill_Date] = useState(new Date());

   

    
 const initalValues ={
    Bill_Date: new Date(),
    po_date:new Date(),
    Bill_no: '',
    Certificate_No:'',
    PONo: '',
    CustomerName: {value:'',label:''},
    GST_NUMBER: '',
   Cust_Address: '',
    Ref: '',
    BankName: {value:'',label:''},
    Branch: '',
    AccNO: '',
    IfscCode: '',
    terms: 'Within 30 Days From Billing Date',
    Qty: '',
    Rate: '',
    Total: 0,
    CGSTAmt: 0,
    SGSTAmt: 0,
    IGSTAmt: 0,
    travalCharg: 0,
    courierCharg:0,
    GrandTotalAmt: 0,
    pmiflag:'',
    Test_Type:'',
    If_IGST:'',
    Round_up:'',
   }

 

 useEffect(()=>{
  const fetchData = async () => {
    try {
      const response = await getAllCustomer();
     
      setCust(() => {
        const customerOp = response.map((row) => { return { value: row.Cust_Id, label: row.Cust_Name, GST_NUMBER:row.GST_NO, Cust_Address:row.Cust_Address } });
        return customerOp; 
    });
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };
  fetchData();
},[])

useEffect(() => {
  const fetchBill_no = async () => {
    try {
     
      const getBill_no = await getAutoBill_no({bill_date});
      
      const maxNumber = getBill_no.data.nextBill_no;
      formik.setFieldValue('Bill_no' , maxNumber) ;

    } catch (error) {
      console.log(error);
    }
  }
  fetchBill_no();
},[bill_date])

useEffect(()=>{
  const fetchDataB = async () => {
    try {
      const response = await getAllNeft_bank();
      
      setBank(() => {
        const bankOp = response.map((row) => { return { value: row.bank_id, label: row.bank_name , Branch: row.branch_name, AccNO: row.account_no, IfscCode: row.ifsc_code} });
        return bankOp; 
    });
    } catch (error) {
      console.error("Error fetching banks:", error);
    }
  };
  fetchDataB();
},[])


 useEffect(() => {
  
    const fetchBillData = async () =>
    {
        try {
            setLoading(true);
            const getBillData = await getBillById(billId);
            
            setLatestId(true)
            const quantity = getBillData.data.rows.map((row,index)=>{
                return row.Qty
            })
            const sum = quantity.reduce((accumulator, currentValue) => {
              return accumulator + parseFloat(currentValue);
          }, 0);
          
          
          setSumOfQuantity(sum);
            
            const billMastData = getBillData.data.billMast;
              const CustNew = cust.filter((item)=>item.value ==  billMastData.CustomerName)[0];
              const BankNew = bank.filter((item)=>item.value ==  billMastData.BankName)[0];
              const certificateNosArray = billMastData.Certificate_No.split(',');
        
        setCertificateNos(certificateNosArray);
        setCheckedCertificateNos(certificateNosArray);

        setPmiFlag(billMastData.Pmiflag === "Hours" ? 'Hours' : 'Visit');

        setIf_igst(billMastData.If_IGST === "checked" ? "checked" : '');

        setInpoint(billMastData.Round_up === 'roundup' ? 'roundup' : '');


              const parseDate = (dateString) => {
                  if (dateString) {
                      const parts = dateString.split('/');
                      return `${parts[2]}-${parts[1]}-${parts[0]}`;
                  }
                  return null;
              };
            const sortedData = {

                Bill_Date: parseDate(billMastData.Bill_Date),
                po_date:parseDate(billMastData.po_date),
                Bill_no:billMastData.Bill_no,
                PONo:billMastData.PONo,
                // Certificate_No:checkedCertificateNos,
                CustomerName:CustNew,
                GST_NUMBER:billMastData.GST_NUMBER,
               Cust_Address:billMastData.Cust_Address,
                Ref: billMastData.Ref,
                BankName: BankNew,
                Branch: billMastData.Branch,
                AccNO: billMastData.AccNO,
                IfscCode: billMastData.IfscCode,
                terms: billMastData.terms,
                Qty: billMastData.Hours_Qty,
                Rate: billMastData.Rate,
                Total: billMastData.Total,
                CGSTAmt: billMastData.CGSTAmt,
                SGSTAmt: billMastData.SGSTAmt,
                IGSTAmt: billMastData.IGSTAmt,
                travalCharg: billMastData.travalCharg,
                courierCharg: billMastData.courierCharg,
                GrandTotalAmt: billMastData.GrandTotalAmt,
                // pmiflag:billMastData.pmiflag
            };
            console.log('sortedData',sortedData);
            formik.setValues(sortedData);
            // formik.setFieldValue('Qty', sumOfQuantity);
            setSelectedValue(billMastData.Test_Type);
            // setReferenceIds(billMastData.Ref);
            setNeftCheck(billMastData.neftCheck);
            setGstCheck(billMastData.gstCheck);
            setRoundup(billMastData.roundup);
            
            

            const sortedRows = getBillData.data.rows.map((row, index) => ({
              id: row.id,
              srNo: index + 1,
              ...row
          }));
          
          setRows(sortedRows);
          console.log("hiiii",rows)
        } catch (error) {
            console.log("Error in Fetching Bill Data",error);    
        }finally{
          setLoading(false);
        }
    };
    if(isEdit)
    {
        fetchBillData();
    }
 }, [isEdit,billId,cust, bank]);

 const formik = useFormik({
    initialValues:initalValues,
    validationSchema:Yup.object().shape({
      CustomerName:Yup.object().notRequired(),
        Total:Yup.number().notRequired(),
        Ref:Yup.string().notRequired(),
        GrandTotalAmt:Yup.number().notRequired(),
        BankName:Yup.object().notRequired(),

    }),
    onSubmit: async(values, { setErrors, setStatus, setSubmitting, resetForm }) => {
      
        try {
          setLoading(true);
          console.log("rows",rows);
          values.Refs = referenceIds;
          values.rows = rows;
          values.pmiflag = pmiFlag;
          values.If_IGST = if_igst;
          values.Round_up = inpoint;
          const dataToSend = {
            ...values,
            Test_Type: selectedValue,
            Certificate_No: checkedCertificateNos 
        };

          let response = {};

          

          if (isEdit) {
            response = await updateBill( billId, dataToSend);
            console.log(response)
            setConfirmationMessage("Bill Updated successfully");
            setIsConfirmationOpen(true);
            setColor('green')          } else {
            response = await addBill(dataToSend);
          
            console.log("response",response);
            if(response.status==200){
              setConfirmationMessage(response.data); 
            setIsConfirmationOpen(true);
            setColor('green')
              // setLatestId(true);
              // resetForm();
              // setRows([]);
              // setCertificateNos([]);
              // setSelectedValue('');
              // setPmiFlag('');
              localStorage.removeItem('val');
              setTimeout(() => {
                navigate('/BillMaster');
              }, 1000); 
            }
              else{
                setConfirmationMessage("Somthing Went Wrong!");
            setIsConfirmationOpen(true);
            setColor('error')
              }
          }
          // navigate('/BillMaster')
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.response.data.message });
          setSubmitting(false);
        }finally{
          setLoading(false);
        }
      }
      
     
  });
const handlep=()=>{
  if(billId){
    handlePrintforedit()
  }else{
    handlePrint()
  }
}
  const handlePrint= async()=>{
  
    try {
      setLoading(true)
      const response = await getPrint();
      
      setOpenPDFModal(true);
      const filepath=response.returnpath
    
      setPdfData(filepath);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }finally{
      setLoading(false)
    }

}


  
  useEffect(() =>
  {
    if(formik.values.Total >0 ){
      console.log("hello")
    }
    const x= (formik.values.Rate * sumOfQuantity).toFixed(2);
    formik.setFieldValue('Total',x);
  },[sumOfQuantity,formik.values.Rate]);


  useEffect(() => {


    
    if (gstCheck) {
      
        const calculatedIGSTAmt = (formik.values.Total * 0.18).toFixed(2);
       
        formik.setFieldValue('IGSTAmt', calculatedIGSTAmt);
        formik.setFieldValue('CGSTAmt',0);
        formik.setFieldValue('SGSTAmt',0);
    } else {
      
        formik.setFieldValue('IGSTAmt', 0);
        const calculatedCGSTAmt = (formik.values.Total* 0.09).toFixed(2);
        const calcualtedSGSTAmt = (formik.values.Total*0.09).toFixed(2);
        formik.setFieldValue('SGSTAmt',calcualtedSGSTAmt);
        formik.setFieldValue('CGSTAmt',calculatedCGSTAmt);
    }
}, [gstCheck,formik.values.Total]);


async function handleRadioButton(value){

  if (Number.isInteger(value)) {
    setSelectedCustomerId(value);
    localStorage.setItem('ids',value)
 
  }
  if(value == 'Eddy' || value == 'Dye'|| value == 'PMI' || value == 'Magnetic'|| value == 'Ultrasonic'|| value == 'Spark'|| value == 'Spectro' ){
    localStorage.setItem('val',value)
  }
  const values=localStorage.getItem('val')
  const ids=localStorage.getItem('ids')
  let testtype; 

  if(values == 'Eddy'){
    testtype = 'eddy';
  }
  else if(values == 'Dye'){
    testtype = 'dye';
  }
  else if(values == 'PMI'){
    testtype = 'pmi';
  }
  else if(values == 'Magnetic'){
    testtype = 'magnetic';
      }
  else if(values == 'Ultrasonic'){
    testtype = 'ultrasonic';
  }
  else if(values == 'Spark'){
    testtype = 'spark';

  }
  else if(values == 'Spectro'){
    testtype = 'spectro'

  }
  else{
    testtype = '';
  }
  
 let custid
  if(selectedCustomerId !==null){
custid=selectedCustomerId
  }else{
    custid=ids
  }
  if (testtype && custid ) {
  const res= await getCertificateNo({custId:custid,testT:testtype});
 
  setCertificateNos(res.data.map(item => item.Certificate_No)); 
  localStorage.removeItem('ids');
  
  }

  if (testtype === 'magnetic') {   
    setDefaultRow(1);
  } else {
    setDefaultRow(0);
    console.log('magnetic error');
  }
  
}

function handleCheckboxChange(certificateNo) {
  if (checkedCertificateNos.includes(certificateNo)) {
    setCheckedCertificateNos(prev => prev.filter(item => item !== certificateNo));
  } else {
    setCheckedCertificateNos(prev => [...prev, certificateNo]);
  }
}




const columns = [
  { field: 'srNo', headerName: 'Sr.No.', flex: 1 },
  { field: 'Certificate_No', headerName: 'Cert No.', flex: 1 },
  { field: 'Description', headerName: 'Particulars', flex: 1, },
  { field: 'Qty', headerName: 'Qty', flex: 1 },
  { field: 'Weight', headerName: 'Weight', flex: 1 },
  { field: 'UOM', headerName: 'UOM', flex: 1 },
  { field: 'Unit_Rate', headerName: 'Unit Rate', flex: 1 ,editable: true },
  { field: 'Cost', headerName: 'Cost', flex: 1 },
  { field: 'Remarks', headerName: 'Remarks', flex: 1},
];
const [uomValues, setUOMValues] = useState({});
const handleWeightChange = (id, value) => {
  const updatedRows = rows.map(row => {
    if (row.id === id) {
      return {
        ...row,
        Weight: value
      };
    }
    return row;
  });
  setRows(updatedRows);
};
const handleqtyChange = (id, value) => {
  const updatedRows = rows.map(row => {
    if (row.id === id) {
      return {
        ...row,
        Qty: value
      };
    }
    return row;
  });
  setRows(updatedRows);
};
const handleDesChange = (id,value) => {
  
  const updatedRows = rows.map(row => {
    if (row.id === id) {
      return {
        ...row,
        Description: value
      };
    }
    // setDescription(Description);
    return row;
  });
  setRows(updatedRows);
};

const handleUOMChange = (id, value) => {
  
  setUOMValues(prevState => ({
    ...prevState,
    [id]: value
  }));

  const updatedRows = rows.map(row => {
    if (row.id === id) {
    
      const { cost, UOM } = calculateCosts(row.Unit_Rate, uomValues[row.id],value, row.Qty, row.Weight);
    
      return { ...row, Cost: cost,UOM:UOM };
    }
    return row;
  });
  const totalCost = updatedRows.reduce((acc, row) => acc + row.Cost, 0);

  formik.setFieldValue('Total', totalCost);
  setRows(updatedRows);
};
const [umoval,setumoval]=useState('')
const calculateCosts = (value, uom, qty, weight) => {
  let result = {};

  if (uom === 'KG') {
    result.UOM = 'KG';
    result.cost = parseFloat(value) * parseFloat(weight);
} else if (uom === 'Visit'){
    result.UOM = 'Visit';
    result.cost = parseFloat(value)
  }
  else {
    result.UOM = 'N';
    result.cost = parseFloat(value) * parseFloat(qty);
  }


  return result;
};
const handleEditCellChange = (id, value) => {
 
  const updatedRows = rows.map(row => {
    if (row.id === id) {
        const { cost, UOM } = calculateCosts(value, uomValues[row.id], row.Qty, row.Weight);
        return { ...row, Unit_Rate: parseFloat(value), Cost: cost, UOM: UOM };
      
    }
    return row;
  });
  const totalCost = updatedRows.reduce((acc, row) => acc + row.Cost, 0);

  formik.setFieldValue('Total', totalCost);
  setRows(updatedRows);
};


const calculateCost = (data) => {
  console.log(data)
  return data.map(item => ({
    ...item,
    Cost: (rows.Cost )
  }));
};


const handlePrintforedit = async()=>{
  try {
    setLoading(true);
    const getPDF = await getBillMastPDF(billId);
    setOpenPDFModal(true);
    const filepath=getPDF.data.returnpath
    setPdfData(filepath);
    
  } catch (error) {
    console.error("Error while printing:", error);
  }finally{
    setLoading(false);

  }
}



async function fetchData() {
  if (!isEdit) {
    if (checkedCertificateNos && checkedCertificateNos.length > 0) {
      try {
        const data = await getDescriptionDataByCertificateNo({
          cert_Id: checkedCertificateNos,
          testT: selectedValue
        });
        let srNo = defaultRow === 1 ? 2 : 1; 
        const newData = data.data.map(item => ({
          id: item.id,
          ...item,
          srNo: srNo++,
          Description: `${item.DescriptionRef}` 
        }));

        const initialUnitRateMap = {};
        newData.forEach(item => {
          initialUnitRateMap[item.id] = item.Unit_Rate || 0;
        });

        setUnitRateMap(initialUnitRateMap);

        let updatedRows = calculateCost(newData, initialUnitRateMap);

        if (defaultRow === 1) {
          const defaultRowData = {
            srNo: 1,
            Description: 'Kerosin for test',
            Certificate_No: 0,
            Qty: 3,
            Weight: 3,
            Unit_Rate: 0,
            Remarks: 'n',
            Cost: 0
          };
          updatedRows = [defaultRowData, ...updatedRows];
        }

        setRows(updatedRows);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      setRows([]);
    }
  }
}


useEffect(() => {
  fetchData();
}, [checkedCertificateNos, selectedValue,defaultRow,referenceIds]);


// async function fetchRefID(){
//   if (!isEdit) {
//     if (checkedCertificateNos && checkedCertificateNos.length > 0){
//       let testtype;
//       const values = localStorage.getItem('val');
//       if(values == 'Eddy'){
//         testtype = 'eddy';
//       }
//       else if(values == 'Dye'){
//         testtype = 'dye';
//       }
//       else if(values == 'PMI'){
//         testtype = 'pmi';
//       }
//       else if(values == 'Magnetic'){
//         testtype = 'magnetic';
//           }
//       else if(values == 'Ultrasonic'){
//         testtype = 'ultrasonic';
//       }
//       else if(values == 'Spark'){
//         testtype = 'spark';
    
//       }
//       else if(values == 'Spectro'){
//         testtype = 'spectro'
    
//       }
//       else{
//         testtype = '';
//       }
//     const refdata = await getReferance_Id({
//       cert_Id: checkedCertificateNos,
//       testT: testtype
//     });
//     console.log('Reference IDs:', refdata);
//     const refid = refdata.data; 
//     if (Array.isArray(refid) && refid.length > 0) {
//       const joinedIds = refid.map(item => item.Referance_Id).join(', ');
//       setReferenceIds(joinedIds);
//     } else if (typeof refid === 'object' && refid.Referance_Id) {
//       setReferenceIds(refid.Referance_Id);
//     } else {
//       setReferenceIds(''); 
//     }
//   }
//   }
// }
// async function fetchRefID() {
//   if (!isEdit) {
//     if (checkedCertificateNos && checkedCertificateNos.length > 0) {
//       let testtype;
//       const values = localStorage.getItem('val');
//       if (values === 'Eddy') {
//         testtype = 'eddy';
//       } else if (values === 'Dye') {
//         testtype = 'dye';
//       } else if (values === 'PMI') {
//         testtype = 'pmi';
//       } else if (values === 'Magnetic') {
//         testtype = 'magnetic';
//       } else if (values === 'Ultrasonic') {
//         testtype = 'ultrasonic';
//       } else if (values === 'Spark') {
//         testtype = 'spark';
//       } else if (values === 'Spectro') {
//         testtype = 'spectro';
//       } else {
//         testtype = '';
//       }

//       const refdata = await getReferance_Id({
//         cert_Id: checkedCertificateNos,
//         testT: testtype
//       });

//       console.log('Reference IDs:', refdata);
//       const refid = refdata.data;

//       if (Array.isArray(refid) && refid.length > 0) {
//         const joinedIds = refid.map(item => item.Referance_Id).join(', ');
//         setReferenceIds(joinedIds);
//       } else if (typeof refid === 'object' && refid.Referance_Id) {
//         setReferenceIds(refid.Referance_Id);
//       } else {
//         setReferenceIds('');
//       }
//     } else {
//       // Reset reference IDs if there are no checked certificate numbers
//       setReferenceIds('');
//     }
//   }
// }


// useEffect(() => {
//   fetchRefID();
// },[checkedCertificateNos,referenceIds]);


const handleDateChange = (date) => {
  formik.setFieldValue(
    "po_date",
    date ? dayjs(date).format('YYYY-MM-DD') : dayjs(formik.values.assignedDate).format('YYYY-MM-DD') || null
  );
};

const handleDateChange1 = (date) => {
  formik.setFieldValue(
    "Bill_Date",
    date ? dayjs(date).format('YYYY-MM-DD') : dayjs(formik.values.assignedDate).format('YYYY-MM-DD') || null
  );
  console.log("hit")
  setBill_Date(date ? dayjs(date).format('YYYY-MM-DD') : dayjs(formik.values.assignedDate).format('YYYY-MM-DD') || null)
}
useEffect(() => {
  let y = Number(formik.values.Total) + Number(formik.values.CGSTAmt) + Number(formik.values.SGSTAmt) + Number(formik.values.IGSTAmt) + Number(formik.values.travalCharg) + Number(formik.values.courierCharg);

  if (inpoint == "roundup") {
    y = Math.round(y);
  } else {
    y = y.toFixed(2); 
  }

  formik.setFieldValue('GrandTotalAmt', y);
}, [formik.values.Total, formik.values.CGSTAmt, formik.values.SGSTAmt, formik.values.IGSTAmt, formik.values.travalCharg, formik.values.courierCharg, roundup]);

const handleRoundupChange = (event) => {
  const isChecked = event.target.checked;
  setRoundup(isChecked);

  let y = Number(formik.values.Total) + Number(formik.values.CGSTAmt) + Number(formik.values.SGSTAmt) + Number(formik.values.IGSTAmt) + Number(formik.values.travalCharg) + Number(formik.values.courierCharg);

  if (isChecked) {
    setInpoint('roundup');
    y = Math.round(y);
   
  } else {
    y = Number(y.toFixed(2)); 
    setInpoint('');
  }

  formik.setFieldValue('GrandTotalAmt', y);
};


return (
 
  <div>
    {loading && <LoadingSpinner/>}
      <Typography id="modal-modal-title" variant="h6" component="h2">
               <div style={{display:"grid",gridTemplateColumns:"1fr 1fr 1fr",marginLeft:"7rem", marginBottom:"3rem",rowGap:"3%",columnGap:"0.1%"}}>
                   <div>
                      <Radio
                      size='small'
                      checked={selectedValue === 'Eddy'}
                      onChange={handleRadio}
                      value="Eddy"
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'Eddy' }}/>
                      <label style={{fontSize:"15px"}}>Eddy Current</label>
                    </div>
                   

                    <div>
                      <Radio
                      size='small'
                      checked={selectedValue === 'PMI'}
                      onChange={handleRadio}
                      value="PMI"
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'PMI' }}/>
                      <label style={{fontSize:"15px"}}>PMI Test</label>
                    </div>

                    <div>
                          <Radio
                          size='small'
                          checked={selectedValue === 'Magnetic'}
                          onChange={handleRadio}
                          value="Magnetic"
                          name="radio-buttons"
                          inputProps={{ 'aria-label': 'Magnetic' }}/>
                          <label style={{fontSize:"15px"}}>Magnetic Particle Inseption Test</label>
                    </div>

                      <div>
                          <Radio
                          size='small'
                          checked={selectedValue === 'Spark'}
                          onChange={handleRadio}
                          value="Spark"
                          name="radio-buttons"
                          inputProps={{ 'aria-label': 'Spark' }}/>
                          <label style={{fontSize:"15px"}}>Spark Test</label>
                      </div>

                      <div>
                          <Radio size='small'
                          checked={selectedValue === 'Ultrasonic'}
                          onChange={handleRadio}
                          value="Ultrasonic"
                          name="radio-buttons"
                          inputProps={{ 'aria-label': 'Ultrasonic' }}/>
                          <label style={{fontSize:"15px"}}>Ultrasonic Test</label>
                      </div>

                      <div>
                          <Radio
                          size='small'
                          checked={selectedValue === 'Dye'}
                          onChange={handleRadio}
                          value="Dye"
                          name="radio-buttons"
                          inputProps={{ 'aria-label': 'Dye' }}/>
                          <label style={{fontSize:"15px"}}>Dye Penetrant Inspection Test</label>
                      </div>

                      <div>
                          <Radio
                          size='small'
                          checked={selectedValue === 'Spectro'}
                          onChange={handleRadio}
                          value="Spectro"
                          name="radio-buttons"
                          inputProps={{ 'aria-label': 'Spectro' }}/>
                          <label style={{fontSize:"15px"}}>Spectro / Chemical Analysis</label>
                      </div>
                  
               </div>
               
               <div style={{display:"flex",justifyContent:"space-evenly", alignItems:"center",margin:"20px"}}>
                    <FormControl style={{width:"180px"}}><LocalizationProvider dateAdapter={AdapterDayjs}> <DatePicker name="Bill_Date" format="DD/MM/YYYY" size="small" label="Invoice Date" errors={formik.errors}  value={formik.values.Bill_Date ? dayjs(formik.values.Bill_Date) : null}  onChange={handleDateChange1} /> </LocalizationProvider></FormControl> 

                    <TextField style={{width:"180px"}} id="outlined-basic" label="Bill No" name='Bill_no' variant="outlined"  error={formik.errors.Bill_no && formik.touched.Bill_no}
                    helperText={formik.touched.Bill_no ? formik.errors.Bill_no : ''}
                     onBlur={formik.handleBlur}
                   onChange={formik.handleChange}
                   value={formik.values.Bill_no} />

<FormControl style={{ width: "180px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          name="po_date"
          format="DD/MM/YYYY"
          label="PO Date"
          value={formik.values.po_date ? dayjs(formik.values.po_date) : null}
          onChange={handleDateChange}
        />
      </LocalizationProvider>
    </FormControl> 

                    <TextField style={{width:"180px"}} id="outlined-basic" label="PO No" name='PONo'  variant="outlined"    error={formik.errors.PONo && formik.touched.PONo}
                    helperText={formik.touched.PONo ? formik.errors.PONo : ''}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.PONo} />
               </div>

               <div style={{display:"flex",justifyContent:"space-between", alignItems:"center" , marginInline:"4vw"}}>
                <div style={{display:"flex", flexDirection:"column",rowGap:"2vh"}}>

                    <Autocomplete
                      options={cust}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, value) => {
                        formik.setFieldValue('CustomerName', value || null);
                        formik.setFieldValue('GST_NUMBER', value ? value.GST_NUMBER : '');
                        formik.setFieldValue('Cust_Address', value ? value.Cust_Address : '');
                        setSelectedCustomerId(value ? value.value : null);
                        handleRadioButton(value ? value.value : null)
                      }}
                      value={formik.values.CustomerName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ width: "50vw" }}
                          label="Customer Name"
                          variant="outlined"
                          name="CustomerName"
                          error={formik.errors.CustomerName && formik.touched.CustomerName}
                          helperText={formik.touched.CustomerName ? formik.errors.CustomerName : ''}
                          onBlur={formik.handleBlur}
                        />
                      )}
                    />

                    <TextField
                      style={{ width: "50vw" }}
                      id="outlined-basic"
                      label="GST No."
                      name='GST_NUMBER'
                      variant="outlined"
                      error={formik.errors.GST_NUMBER && formik.touched.GST_NUMBER}
                      helperText={formik.touched.GST_NUMBER ? formik.errors.GST_NUMBER : ''}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.GST_NUMBER}
                    />

                    <TextField
                      style={{ width: "50vw" }}
                      id="outlined-basic"
                      label="Cust_Address"
                      name='Cust_Address'
                      variant="outlined"
                      error={formik.errors.Cust_Address && formik.touched.Cust_Address}
                      helperText={formik.touched.Cust_Address ? formik.errors.Cust_Address : ''}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.Cust_Address}
                    />


                  <TextField
                    style={{ width: "50vw" }}
                    id="outlined-basic"
                    label="Reference"
                    name='Ref'
                    variant="outlined"
                    error={formik.errors.Ref && formik.touched.Ref}
                    helperText={formik.touched.Ref ? formik.errors.Ref : ''}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.Ref} // Bind value to referenceIds state
                  />
                </div>
                {/* <div style={{ display: "flex", flexDirection: "column" }}>
                  <p style={{ fontSize: "15px" }}>Certificate No</p>
                  <div name='certix' style={{ minWidth: "10rem", height: "15rem", border: "2px solid black" }}>
                  {certificateNos.map((certificateNo, index) => (
                          <div key={index}>
                            <input
                              type="checkbox"
                              id={`certificate_${certificateNo}`}
                              name={`certificate_${certificateNo}`}
                              value={certificateNo }
                              checked={checkedCertificateNos.includes(certificateNo)}
                              onChange={() => handleCheckboxChange(certificateNo)}
                            />
                            <label htmlFor={`certificate_${certificateNo}`}>{certificateNo}</label>
                          </div>
                        ))}
                  </div>
                </div> */}
                <div style={{  }}>
  <p style={{ fontSize: "15px" }}>Certificate No</p>
  <div name='certix' style={{ minWidth: "10rem", border: "1px solid black",display: "flex", flexDirection: "column", height: "15rem", overflowY: "auto" }}>
    {certificateNos.map((certificateNo, index) => (
      <div key={index}>
        <input
          type="checkbox"
          id={`certificate_${certificateNo}`}
          name={`certificate_${certificateNo}`}
          value={certificateNo}
          checked={checkedCertificateNos.includes(certificateNo)}
          onChange={() => handleCheckboxChange(certificateNo)}
        />
        <label htmlFor={`certificate_${certificateNo}`}>{certificateNo}</label>
      </div>
    ))}
  </div>
</div>


               </div>

               <br/>
               <TableContainer>
               <Table style={{ border: '1px solid #000' }}>
                    <TableHead style={{ backgroundColor: '#1976d2', color: 'white'  }}>
                        <TableRow>
                            <TableCell>Sr.No.</TableCell>
                            <TableCell>Cert No.</TableCell>
                            <TableCell>Particulars</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>Weight</TableCell>
                            <TableCell>UOM</TableCell>
                            <TableCell>Unit Rate</TableCell>
                            <TableCell>Cost</TableCell>
                            <TableCell>Remarks</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                          
                            <TableRow key={index} style={{ height: '30px' }}>
                                <TableCell>{row.srNo}</TableCell>
                                <TableCell>{row.Certificate_No}</TableCell>
                                {/* <TableCell>{row.Description} - {referenceIds}</TableCell> */}
                                <TableCell>
      <input
        type="text"
        value={row.Description}       
        onChange={(e) => handleDesChange(row.id, e.target.value)}
        style={{ width: '220px', maxHeight: '45px'}}
      />
    </TableCell>
                                <TableCell>
  {uomValues[row.id] === 'KG' ? (
    <input
    type="number"
    value={row.Qty}
    disabled
    style={{ width: '80px', maxHeight: '45px', backgroundColor: '#f5f5f5' }}
  />
  ) : (
    
    <input
      type="number"
      value={row.Qty}
      onChange={(e) => handleqtyChange(row.id, e.target.value)}
      style={{ width: '80px', maxHeight: '45px' }}
    />
  )}
</TableCell>

<TableCell>
  {uomValues[row.id] === 'KG' ? (
    <input
      type="number"
      value={row.Weight}
      onChange={(e) => handleWeightChange(row.id, e.target.value)}
      style={{ width: '80px', maxHeight: '45px' }}
    />
  ) : (
    <input
      type="number"
      value={row.Weight}
      disabled
      style={{ width: '80px', maxHeight: '45px', backgroundColor: '#f5f5f5' }}
    />
  )}
</TableCell>
                            

                                <TableCell>
                                <select
  value={uomValues[row.id] || row.UOM}
  onChange={(e) => handleUOMChange(row.id, e.target.value)}
  style={{ width: '80px', maxHeight: '45px' }}
>
  <option value="N">N</option>
  <option value="KG">KG</option>
  <option value="Visit">Visit</option>
</select>

    </TableCell>
                                <TableCell>
                                <input
    type="number"
    value={row.Unit_Rate}
    onChange={(e) => handleEditCellChange(row.id, e.target.value)}
    style={{ width: '80px', maxHeight: '45px' }}
/>

                                </TableCell>
                                <TableCell>{row.Cost}</TableCell>
                                <TableCell>{row.Remarks}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

             <div style={{display:"flex",width:"100%",marginBlock:"20px",height:"80vh", columnGap:"4rem"}}>
                  <div style={{ marginTop:"20px",width:"50%",marginRight:"1%",marginLeft:"1%",display:"flex",flexDirection:"column",gap:"10%", height:"50vh"}}>

                             <div>
                             <FormControlLabel control={<Checkbox/>} checked={(formik.values.BankName && isEdit) || neftCheck} onChange={neftChecker} label="NEFT" name='neft' />
                             </div>
                              
                             
                                  <Autocomplete
                                    options={bank}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => {
                                      formik.setFieldValue('BankName', value || null);
                                      formik.setFieldValue('Branch', value ? value.Branch : '');
                                      formik.setFieldValue('AccNO', value ? value.AccNO : '');
                                      formik.setFieldValue('IfscCode', value ? value.IfscCode : '');
                                    }}
                                    value={formik.values.BankName}
                                    disabled={!neftCheck && !isEdit}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Bank Name"
                                        variant="outlined"
                                        name="BankName"
                                        error={formik.errors.BankName && formik.touched.BankName}
                                        helperText={formik.touched.BankName ? formik.errors.BankName : ''}
                                        onBlur={formik.handleBlur}
                                      />
                                    )}
                                  />

                                  <TextField
                                    size='small'
                                    id="outlined-basic"
                                    label="Branch Name"
                                    name='Branch'
                                    variant="outlined"
                                    error={formik.errors.Branch && formik.touched.Branch}
                                    helperText={formik.touched.Branch ? formik.errors.Branch : ''}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.Branch}
                                  />

                                  <TextField
                                    size='small'
                                    id="outlined-basic"
                                    label="Account No."
                                    name='AccNO'
                                    variant="outlined"
                                    error={formik.errors.AccNO && formik.touched.AccNO}
                                    helperText={formik.touched.AccNO ? formik.errors.AccNO : ''}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.AccNO}
                                  />

                                  <TextField
                                    size='small'
                                    id="outlined-basic"
                                    label="IFSC Code"
                                    name='IfscCode'
                                    variant="outlined"
                                    error={formik.errors.IfscCode && formik.touched.IfscCode}
                                    helperText={formik.touched.IfscCode ? formik.errors.IfscCode : ''}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.IfscCode}
                                  />

                              

                            
                                  <TextField size='small' id="outlined-basic" label="Terms of Payment" name='terms' variant="outlined" style={{marginTop:"2rem"}} error={formik.errors.terms && formik.touched.terms}
                                   helperText={formik.touched.terms ? formik.errors.terms : ''}
                                   onBlur={formik.handleBlur}
                                   onChange={formik.handleChange}
                                   value={formik.values.terms}/>
                            
                             
                          
                  </div>
                 
                  <div style={{ marginTop:"20px",width:"50%",marginLeft:"1%", padding:"4%", display:"flex", flexDirection:"column",height:"70vh",justifyContent:"space-around",alignItems:"flex-start",gap:"5%",}}>
                     <div>
                     <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>Hours</Grid>
                      <Grid item>
                          <FormControlLabel
                              control={
                                  <Checkbox
                                      checked={pmiFlag === 'Hours'}
                                      onChange={handleHoursChange}
                                  />
                              }
                              label=""
                          />
                      </Grid>
                      <Grid item>Visit</Grid>
                      <Grid item>
                          <FormControlLabel
                              control={
                                  <Checkbox
                                      checked={pmiFlag === 'Visit'}
                                      onChange={handleVisitChange}
                                  />
                              }
                              label=""
                          />
                      </Grid>
                  </Grid>
                     </div>    
                     
                    
                      <div style={{display:"flex", gap:"20px"}}>
                          
                           <TextField 
  size='small' 
  id="outlined-basic" 
  label="Qty" 
  name='Qty' 
  variant="outlined" 
  error={formik.errors.Qty && formik.touched.Qty}
  helperText={formik.touched.Qty ? formik.errors.Qty : ''}
  onBlur={formik.handleBlur}
  onChange={(e) => {
    formik.setFieldValue('Qty', e.target.value);
    setSumOfQuantity(e.target.value);
    if (pmiFlag === 'Hours' || pmiFlag === 'Visit') {
        const qty = parseInt(e.target.value); 
        const rate = parseInt(formik.values.Rate || 0); 
        formik.setFieldValue('Total', qty * rate);
    }
}}
  value={(visitChecked || hoursChecked || pmiFlag) ? formik.values.Qty : ''} 
  disabled={!(visitChecked || hoursChecked || pmiFlag)} 
/>

<TextField 
  size='small' 
  id="outlined-basic" 
  label="Rate" 
  name='Rate' 
  variant="outlined" 
  error={formik.errors.Rate && formik.touched.Rate}
  helperText={formik.touched.Rate ? formik.errors.Rate : ''}
  onBlur={formik.handleBlur}
  onChange={(e) => {
    formik.setFieldValue('Rate', e.target.value);

    if (pmiFlag === 'Hours' || pmiFlag === 'Visit') {
        const rate = parseInt(e.target.value); 
        const qty = parseInt(formik.values.Qty || 0); 
        formik.setFieldValue('Total', rate * qty);
    }
}}
value={(visitChecked || hoursChecked || pmiFlag) ? formik.values.Rate : ''}
disabled={!(visitChecked || hoursChecked || pmiFlag)} 
/>

<TextField 
    size='small' 
    id="outlined-basic" 
    label="Total" 
    name='Total' 
    variant="outlined"  
    error={formik.errors.Total && formik.touched.Total}
    helperText={formik.touched.Total ? formik.errors.Total : ''}
    onBlur={formik.handleBlur}
    value={(pmiFlag === null || pmiFlag === '') ? 
    (rows.length > 0 ? 
        rows.reduce((acc, row) => acc + row.Cost, 0) : 
        0) :
    (formik.values.Qty || 0) * (formik.values.Rate || 0) 
}

/>


                      </div>
                    


                    <div style={{display:"flex", gap:"20px"}}>
                        <TextField size='small' id="outlined-basic" label="CGSTAmt" name='' variant="outlined" value={9} />

                      
                      <TextField size='small' id="outlined-basic" label="CGSTAmt Amount" name='CGSTAmt' variant="outlined" error={formik.errors.CGSTAmt && formik.touched.CGSTAmt}
                            helperText={formik.touched.CGSTAmt ? formik.errors.CGSTAmt : ''}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.CGSTAmt}
                            disabled={(gstCheck || if_igst)} />
          
                        </div>

                        <div style={{display:"flex", gap:"20px"}}>
                            <TextField size='small' id="outlined-basic" label="SGSTAmt" name='' variant="outlined" value={9} />
                            <TextField size='small' id="outlined-basic" label="SGSTAmt Amount" variant="outlined" name='SGSTAmt' error={formik.errors.SGSTAmt && formik.touched.SGSTAmt}
                            helperText={formik.touched.SGSTAmt ? formik.errors.SGSTAmt : ''}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.SGSTAmt}
                            disabled={(gstCheck || if_igst)}/>
                           
                        </div>
                        <div style={{display:"flex", gap:"20px"}}>
                            <TextField size='small' id="outlined-basic" label="IGSTAmt" name='' variant="outlined" value={18} />
                            <TextField size='small' id="outlined-basic" label="IGSTAmt Amount" variant="outlined" name='IGSTAmt' error={formik.errors.IGSTAmt && formik.touched.IGSTAmt}
                            helperText={formik.touched.IGSTAmt ? formik.errors.IGSTAmt : ''}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.IGSTAmt}
                            disabled={!(gstCheck || if_igst)} />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={if_igst == 'checked'}
                                  onChange={gstChecker}
                                />
                              }
                              label="IF IGST"
                            />                      
                        </div>
                     
                      <div style={{display:"flex", gap:"20px"}}>
                          <TextField size='small' id="outlined-basic" label="Travelling Charges" name='travalCharg' variant="outlined" error={formik.errors.travalCharg && formik.touched.travalCharg}
                          helperText={formik.touched.travalCharg ? formik.errors.travalCharg : ''}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.travalCharg} />

                          <TextField size='small' id="outlined-basic" label="Courier Charges" name='courierCharg' variant="outlined" error={formik.errors.courierCharg && formik.touched.courierCharg}
                          helperText={formik.touched.courierCharg ? formik.errors.courierCharg : ''}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.courierCharg} />
                      </div>
                      <div style={{ marginTop:"10px", width:"100%", display:"flex", flexDirection:"row", alignItems: "center", justifyContent: "space-between" }}>
  <FormControlLabel
    control={<Checkbox checked={inpoint == 'roundup'} onChange={handleRoundupChange} />}
    label="Round up"
  />
  <TextField
    size='small'
    id="outlined-basic"
    label="Grand Total"
    name='grandTotal'
    variant="outlined"
    error={formik.errors.GrandTotalAmt && formik.touched.GrandTotalAmt}
    helperText={formik.touched.GrandTotalAmt ? formik.errors.GrandTotalAmt : ''}
    onBlur={formik.handleBlur}
    onChange={formik.handleChange}
    value={formik.values.GrandTotalAmt}
  />
</div>        
             </div>
             </div>

             <div style={{display:"flex", justifyContent:"flex-end",}}>
                <Button onClick={handlep} style={{ backgroundColor: latestId ? "orange" : "gray", color: "white", marginInline: "0.7rem", opacity:  latestId ? 1 : 0.5 }} disabled={!latestId }>PRINT</Button>

                <Button
                onClick={formik.handleSubmit}
                style={{
                backgroundColor: "Green",
                color: "white", 
                marginInline: "0.7rem", 

                }}
                
                >
                {billId === undefined ? "Save" : "Update"}
                </Button>

                <Button onClick ={handleBack} style={{backgroundColor:"red",color:"white", marginInline:"0.7rem"}}>CANCEL</Button>
                <Button onClick ={handleBack} style={{backgroundColor:"blue",color:"white"}}>BACK</Button>
              </div>

                <Dialog
        open={openPDFModal}
        onClose={handlePDFModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
        maxWidth={1200}
      >
        <DialogTitle>
          Invoice
        </DialogTitle>
        
        <iframe title="pdf-view"   src={pdfData && pdfData ? `${APP_BASE_PATH}${pdfData}` : null }width="800px" height="800px"></iframe>
      
        <DialogActions>
          <Button onClick={handlePDFModalClose}>Close</Button>          
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={isConfirmationOpen}
        autoHideDuration={6000}
        message={confirmationMessage}
      color={color}
        onClose={() => setIsConfirmationOpen(false)}
      />   
        </Typography>
    
  </div>
  
)
}

export default CreateBill;
