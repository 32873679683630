import React ,{useEffect,useState} from 'react'
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import dayjs from "dayjs";
import DeleteIcon from '@mui/icons-material/Delete';
import {Autocomplete, IconButton, Chip} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers';
import { getAllCustomersAuto } from "../../../lib/api-customer";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { DataGrid } from '@mui/x-data-grid';
import { getSparkById,updateSpark,createSpark, getAutoCertificateNo, deleteSparkDescription, getPrint, getSparkPDF } from '../../../lib/api-spark';
import { getAllMaterials } from '../../../lib/api-material';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import {Dialog, DialogActions, DialogTitle} from '@mui/material';
import { APP_BASE_PATH } from "../../../lib/api-base-paths";
import CustomSnackbar from '../../../components/common/CustomSnackbar';



const CreateSpark = ({sparkId,isEdit}) => {

    const[cust,setCust]=useState([]);
    const[mate,setMate]=useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [latestId,setLatestId]=useState(false)
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [color,setColor]=useState("")
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [openPDFModal, setOpenPDFModal] = useState(false);
    const [ pdfData, setPdfData] = useState(null);
    const handlePDFModalClose = ()=>{
      setPdfData(null)
      setOpenPDFModal(false)};
    const navigate = useNavigate();
    const [sparkdate, setSparkdate] = useState(() => {
      const today = new Date();
      return today.toISOString().split('T')[0];
    });
    const handleBack = ()=>
    {
      navigate('/Spark')
    } 

      const [rows, setRows] = useState([]);
  const [formikValues, setFormikValues] = useState({
    Description: '',
    Qty: '',
    Weight: '',
    Accepted: '',
    
    Rejected: '',
    Remarks: '',
    
  });
  const [idCounter, setIdCounter] = useState(1);
 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormikValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddRow = () => {
    if (!formikValues.Description || !formikValues.Qty || !formikValues.Weight || !formikValues.Accepted || !formikValues.Rejected || !formikValues.Remarks) {
        alert("Please fill in all required fields.");
        return; 
    }

    const newRow = { id: idCounter, srNo: idCounter, ...formikValues };
    setIdCounter(idCounter + 1);

    if (rows.length > 0) {
        const maxSrNo = Math.max(...rows.map(row => row.srNo));
        newRow.srNo = maxSrNo + 1;
    } else {
        newRow.srNo = 1;
    }

    newRow.id = newRow.srNo;

    setRows(prevRows => [...prevRows, newRow]);

    setFormikValues({
        Description: '',
        Qty: '',
        Weight: '',
        Accepted: '',
        Rejected: '',
        Remarks: '',
    });
};




const handleDelete1 = (id) => {
  deleteSparkDescription(id)
      .then(response => {
          console.log(response.data); 
          setRows(prevRows => prevRows.filter(row => row.id !== id));
      })
      .catch(error => {
          console.error('Error deleting row:', error); // Handle error
      });
};

    

    const initalValues = {
      Cert_Date:new Date(),
      Certificate_No:'',
      Cust_Name:{value:'',label:''},
      Referance_Id:'',
      Material_Nm:[{value:'',label:''}],
      Examstage:'',
      Standard:'',
      Type:'',
      Speed:'',
      Location:'',
      Witness_By:'',
      technician:'',
      designation:'ASNT LEVEL II - UT.MT.PT',
    }

    const columns = [
      { field: 'srNo', headerName: 'Sr.No.',  flex:1 },
      { field: 'Description', headerName: 'Description',flex: 1 },
      { field: 'Qty', headerName: 'Qty', flex: 1 },
      { field: 'Weight', headerName: 'Weight', flex: 1 },
      { field: 'Accepted', headerName: 'Accepted', flex: 1 },
      { field: 'Rejected', headerName: 'Rejected', flex: 1 },
      { field: 'Remarks', headerName: 'Remarks', flex: 1 },
      { field: 'action', headerName: 'Action', flex: 1,
      renderCell: (params) => (
        <div>
        <IconButton   onClick={()=> handleDelete1(params.row.id) }>
          <DeleteIcon sx={{ color: 'red' }} />
        </IconButton>
      </div>
      ),},
    ];


    useEffect(()=>{
      const fetchData = async () => {
        try {
          const response = await getAllCustomersAuto();
          setCust(() => {
            const customerOp = response.map((row) => { return { value: row.Cust_Id, label: row.Cust_Name } });
            return customerOp; 
        });
        } catch (error) {
          console.error("Error fetching customers:", error);
        }
      };
      fetchData();
    },[])


    useEffect(()=>{
      const fetchData = async () => {
        try {
          const response = await getAllMaterials();
          const materialOp = response.map((row) => { return { value: row.Material_Id, label: row.Material_Nm } });
          setMate(materialOp);
        } catch (error) {
          console.error("Error fetching materials:", error);
        }
      };
      fetchData();
    },[])


    useEffect(() => {
      const fetchCertificateNo = async () => {
        try {
          const getCertificateNo = await getAutoCertificateNo(sparkdate);
          const maxNumber = getCertificateNo.data.nextCertificateNo;
          formik.setFieldValue('Certificate_No' , maxNumber) ;

        } catch (error) {
          
        }
      }
      fetchCertificateNo();
    },[sparkdate])

 
  useEffect(() => {
    const fetchData = async () => {
        try {
          setLoading(true);
            const getSparkData = await getSparkById(sparkId);
            setLatestId(true);
            const sparkData = getSparkData.data.spark;

            const parseDate = (dateString) => {
                if (dateString) {
                    const parts = dateString.split('/');
                    return `${parts[2]}-${parts[1]}-${parts[0]}`;
                }
                return null;
            };

            const [MaterialValues] = await Promise.all([
              JSON.parse(sparkData.Material_Test),
          ]);

          const selectedOptions = MaterialValues.map(value => ({
              value,
              label: mate.find(option => option.value === value)?.label || ''
          }));
          setSelectedValues(selectedOptions);
    
          const CustNew = cust.filter(item => item.value == sparkData.Cust_Name)[0];

            const sortedData = {
                Cert_Date: parseDate(sparkData.Cert_Date),
                Cust_Name: CustNew,
                Certificate_No: sparkData.Certificate_No,
                Referance_Id: sparkData.Referance_Id,
                Material_Nm: selectedOptions,
                Examstage: sparkData.Examstage,
                Standard: sparkData.Standard,
                Speed: sparkData.Speed,
                Type: sparkData.Type,
                Witness_By: sparkData.Witness_By,
                technician: sparkData.technician,
                Location: sparkData.Location,
                designation: sparkData.designation,
            };

            formik.setValues(sortedData);

            const sortedRows = getSparkData.data.rows.map((row, index) => ({
                id: row.id,
                srNo: index + 1,
                ...row
            }));

            setRows(sortedRows);
            setLoading(false);
        } catch (error) {
            console.log("Error in Fetching Spark Data", error);
        }finally{
          setLoading(false);
        }
    };

    if (isEdit) {
        fetchData();
    }
}, [isEdit, sparkId, cust, mate]);


   const formik = useFormik({
    initialValues:initalValues,
    validationSchema:Yup.object().shape({
        Cert_Date:Yup.date().notRequired(),

        Certificate_No:Yup.number().notRequired(),

        Cust_Name:Yup.object().notRequired(),

        Referance_Id:Yup.string().notRequired(),

        Material_Nm:Yup.array().notRequired(),

        Examstage:Yup.string().notRequired(),

        Standard:Yup.string().notRequired(),

        Speed:Yup.string().notRequired(),

        Location:Yup.string().notRequired(),

        Witness_By:Yup.string().notRequired(),
        Type:Yup.string().notRequired(),
        technician:Yup.string().notRequired(),
        designation:Yup.string().notRequired(),

    }),
    onSubmit: async(values, { setErrors, setStatus, setSubmitting,resetForm }) => {
        try {
          setLoading(true);
          let response = {};
      
          values.rows = rows;

          if (isEdit) {
            response = await updateSpark(sparkId, values);
            setConfirmationMessage("Spark Updated successfully");
            setIsConfirmationOpen(true);
            setColor('green')
          } else {
            response = await createSpark(values);
            if(response.status==200){
              setConfirmationMessage(response.data);
            setIsConfirmationOpen(true);
            setColor('green')
              setLatestId(true);
              resetForm();
              setSelectedValues([]); 
              setRows([]);
            }
              else{
                setConfirmationMessage("Somthing Went Wrong!");
            setIsConfirmationOpen(true);
            setColor('error')
              }
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.response.data.message });
          setSubmitting(false);
        }finally{
          setLoading(false);
        }
      }
      
  });

  const handleAutocompleteChange = (event, value) => {
    setSelectedValues(value);
    formik.setFieldValue('Material_Nm', value);
  };
  
  const handleDelete = (valueToDelete) => () => {
    setSelectedValues((prevValues) =>
      prevValues.filter((value) => value.label !== valueToDelete.label)
    );
  };
  const handlep=()=>{
    if(sparkId){
      handlePrintforedit()
    }else{
      handlePrint()
    }
  }

  const handlePrintforedit = async()=>{
    try {
      setLoading(true);
      const getPDF = await getSparkPDF(sparkId);
      setOpenPDFModal(true);
      const filepath=getPDF.data.returnpath
      setPdfData(filepath);
      
    } catch (error) {
      console.error("Error while printing:", error);
    }finally{
      setLoading(false);
  
    }
  }
  const handlePrint= async()=>{
  
    try {
      setLoading(true)
      const response = await getPrint();
      
      setOpenPDFModal(true);
      const filepath=response.returnpath
    
      setPdfData(filepath);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }finally{
      setLoading(false)
    }

}
  

  return (
    
    <div>
       {loading && <LoadingSpinner/>}
        <div style={{display:"flex", flexDirection:"column",gap:"1rem"}}>
              <div style={{ display: 'flex', alignItems: 'center', gap:"0.5rem" }}>
              <FormControl style={{width:"180px"}}><LocalizationProvider dateAdapter={AdapterDayjs}> <DatePicker name="Cert_Date" format="DD/MM/YYYY" label="Date"  errors={formik.errors}  value={formik.values.Cert_Date ? dayjs(formik.values.Cert_Date) : null} 
              // onChange={(date) => {formik.setFieldValue('Cert_Date', date);}}
              onChange={(date) => {
                formik.setFieldValue(
                  "Cert_Date",
                  date ? dayjs(date).format('YYYY-MM-DD') : dayjs(formik.values.assignedDate).format('YYYY-MM-DD') || null
                );
                setSparkdate(date ? dayjs(date).format('YYYY-MM-DD') : dayjs(formik.values.assignedDate).format('YYYY-MM-DD') || null)
              }}/> </LocalizationProvider></FormControl> 
                  <TextField style={{width:"37.5vw"}}   id="outlined-basic" label="Certificate No." name='Certificate_No' variant="outlined" error={formik.errors.Certificate_No && formik.touched.Certificate_No}
                      helperText={formik.touched.Certificate_No ? formik.errors.Certificate_No : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Certificate_No}/> 
              </div>
              <div>
          <Autocomplete
          options={cust}
          getOptionLabel={(option) => option.label}
          onChange={(event, value) => formik.setFieldValue('Cust_Name', value || null)}
          value={formik.values.Cust_Name}
          renderInput={(params) => (
            <TextField
            {...params}
            sx={{width:"50vw"}}
            label="Customer Name"
            variant="outlined"
            name="Cust_Name"
            error={formik.errors.Cust_Name && formik.touched.Cust_Name}
            helperText={formik.touched.Cust_Name ? formik.errors.Cust_Name : ''}
            onBlur={formik.handleBlur}
            />
          )}
        />
            
        </div>
        <div>
            <TextField style= {{width:"50vw"}}   id="outlined-basic" label="Reference" name='Referance_Id' variant="outlined" error={formik.errors.Referance_Id && formik.touched.Referance_Id}
                      helperText={formik.touched.Referance_Id ? formik.errors.Referance_Id : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Referance_Id} />
          
        </div>
        </div>
        
        <br/>
        <div >
        <div>
      <div style={{ display: 'flex', alignItems: 'center', columnGap: '1%' }}>

      
      
        <TextField
          id="outlined-basic"
          label="Part Description"
          name="Description"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Description}
        />

        <TextField
          id="outlined-basic"
          label="Qty"
          name="Qty"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Qty}
        />

        <TextField
          id="outlined-basic"
          label="Weight"
          name="Weight"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Weight}
        />

        <TextField
          id="outlined-basic"
          label="Accepted"
          name="Accepted"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Accepted}
        />

        <TextField
          id="outlined-basic"
          label="Rejected"
          name="Rejected"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Rejected}
        />

        <TextField 
          id="outlined-basic" 
          label="Remarks" 
          name='Remarks' 
          variant="outlined" 
          style={{ width: "10vw" }} 
          onChange={handleChange}
          value={formikValues.Remarks}
        />
        <Button onClick={handleAddRow} style={{ backgroundColor: "red", color: "white" }} variant="contained">Add</Button>
      </div>
      <div style={{ marginBlock: "20px" }}>
        <DataGrid
          autoHeight
          density="compact"
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </div>
     
    </div>
        </div>



               <div style={{display:"grid" , gridTemplateColumns:"1fr 1fr 1fr", gap:"2rem", marginTop:"3vh",marginBottom:"8vh"}}>
                  

                    <Autocomplete
                        multiple
                        options={mate}
                        getOptionLabel={(option) => option.label}
                        onChange={handleAutocompleteChange}
                        value={selectedValues}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          id='outlined-basic'
                          label='Material Name'
                          name='Material_Nm'
                          variant='outlined'
                          fullWidth
                        />
                        )}
                        renderTags={(value) =>
                          value.map((option, index) => (
                            <div key={index} style={{ display: 'flex', overflowX: 'auto', maxWidth: '100%' }}>
                              <Chip
                                key={index}
                                label={option ? option.label : ""}
                                variant="outlined"
                                onDelete={handleDelete(option)}
                                sx={{ margin: '2px' }}
                              />
                            </div>
                          ))
                        }
                      />
                        <TextField   id="outlined-basic" label="Examination Stage" name='Examstage' variant="outlined" error={formik.errors.Examstage && formik.touched.Examstage}
                      helperText={formik.touched.Examstage ? formik.errors.Examstage : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Examstage} />

                        <TextField   id="outlined-basic" label="Standard of Specification" name='Standard' variant="outlined" error={formik.errors.Standard && formik.touched.Standard}
                      helperText={formik.touched.Standard ? formik.errors.Standard : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Standard} />
                  
                  
                        <TextField   id="outlined-basic" label="Type" name='Type' variant="outlined" error={formik.errors.Type && formik.touched.Type}
                      helperText={formik.touched.Type ? formik.errors.Type : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Type} />
                        <TextField   id="outlined-basic" label="Wheel Speed" name='Speed' variant="outlined" error={formik.errors.Speed && formik.touched.Speed}
                      helperText={formik.touched.Speed ? formik.errors.Speed : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Speed} />

                        <TextField   id="outlined-basic" label="Location" name='Location' variant="outlined" error={formik.errors.Location && formik.touched.Location}
                      helperText={formik.touched.Location ? formik.errors.Location : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Location} />
                  
                  
                        <TextField   id="outlined-basic" label="Witness By" name='Witness_By' variant="outlined" error={formik.errors.Witness_By && formik.touched.Witness_By}
                      helperText={formik.touched.Witness_By ? formik.errors.Witness_By : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Witness_By} />
                        <TextField   id="outlined-basic" label="technician" name='technician' variant="outlined" error={formik.errors.technician && formik.touched.technician}
                      helperText={formik.touched.technician ? formik.errors.technician : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.technician} />

                      <TextField   id="outlined-basic" label="Designation" name='designation' variant="outlined" 
                        error={formik.errors.designation && formik.touched.designation}
                        helperText={formik.touched.designation ? formik.errors.designation : ''}
                         onBlur={formik.handleBlur}
                       onChange={formik.handleChange}
                       value={formik.values.designation} />
                  
                  <div style={{display:"flex",gap:"3rem"}}>
                        
                  </div>
               </div>
               
               <div style={{display:"flex", justifyContent:"flex-end",}}>
                  <Button onClick={handlep} style={{ backgroundColor: latestId ? "orange" : "gray", color: "white", marginInline: "0.7rem", opacity: latestId ? 1 : 0.5 }} disabled={!latestId}>PRINT</Button>

                  <Button
                  onClick={formik.handleSubmit}
                  style={{
                  backgroundColor: "Green",
                  color: "white", 
                  marginInline: "0.7rem", 
                 
                  }}
                 
                  >
                  {sparkId === undefined ? "Save" : "Update"}
                  </Button>

                  <Button onClick ={handleBack} style={{backgroundColor:"red",color:"white", marginInline:"0.7rem"}}>CANCEL</Button>
                  <Button onClick ={handleBack} style={{backgroundColor:"blue",color:"white"}}>BACK</Button>
                </div>

                <Dialog
        open={openPDFModal}
        onClose={handlePDFModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
        maxWidth={1200}
      >
        <DialogTitle>
          Invoice
        </DialogTitle>
        
        <iframe title="pdf-view"   src={pdfData && pdfData ? `${APP_BASE_PATH}${pdfData}` : null }width="800px" height="800px"></iframe>
      
        <DialogActions>
          <Button onClick={handlePDFModalClose}>Close</Button>          
        </DialogActions>
      </Dialog>

      <CustomSnackbar
        open={isConfirmationOpen}
        message={confirmationMessage}
      color={color}
        onClose={() => setIsConfirmationOpen(false)}
      />
    </div>
 
  )
}

export default CreateSpark;
