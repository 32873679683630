import axios from 'axios';
import { SPARK_BASE_PATH } from './api-base-paths';

export const getAllSparks = () => {
    try {
      return axios.get(`${SPARK_BASE_PATH}/getAllSpark`);
    } catch (error) {
      console.error('Error fetching all Sparks', error);
      throw error;
    }
  };

  export const createSpark = (data) => {
    return axios.post(`${SPARK_BASE_PATH}/createSpark`, data);
  };

  export const getSparkByCertificateNo = (id) => {
    return axios.get(`${SPARK_BASE_PATH}/getSparkByCertificateId/${id}`);
  }; 

  export const getAutoCertificateNo = (date) => {
    const data = {
      date: date
    };
    return axios.post(`${SPARK_BASE_PATH}/getAutoCertificateNo`,data);
  }; 

  export const getSparkById = (id) => {
    return axios.get(`${SPARK_BASE_PATH}/getSparkById/${id}`);
  }; 

  export const updateSpark = (id, data) => {
    return axios.put(`${SPARK_BASE_PATH}/updateSpark/${id}`, data);
  };

  export const deleteSpark = (id) => {
    return axios.delete(`${SPARK_BASE_PATH}/deleteSpark/${id}`);
  }; 

  export const createSparkDescription = (data) => {
    return axios.post(`${SPARK_BASE_PATH}/createSparkDescription`, data);
  };

  export const createSparkMagneticMaterial = (data) => {
    return axios.post(`${SPARK_BASE_PATH}/createSparkMagneticMaterial`, data);
  };


  export const deleteSparkDescription = (id) => {
    return axios.delete(`${SPARK_BASE_PATH}/deleteSparkDescription/${id}`);
  }; 

  export const deleteSparkMagneticMaterial = (id) => {
    return axios.delete(`${SPARK_BASE_PATH}/deleteSparkMagneticMaterial/${id}`);
  }; 
  
  export const getSparkDescriptionById = (id) => {
    return axios.get(`${SPARK_BASE_PATH}/getSparkDescriptionById/${id}`);
  }; 

  export const getSparkMaterialUsed = (id) => {
    return axios.get(`${SPARK_BASE_PATH}/getSparkMaterialUsed/${id}`);
  }; 

  export const getSparkPDF = (id) => {
    return axios.put(`${SPARK_BASE_PATH}/getSparkPDF/${id}`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    });
  };

  export const getPrint = async () => {
    try {
        const response = await axios.get(`${SPARK_BASE_PATH}/getPrint`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all customer:', error);
        throw error;
    }
};