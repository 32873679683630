import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import { DataGrid} from '@mui/x-data-grid';

import EditIcon from '@mui/icons-material/Edit';
import {Alert, IconButton, Snackbar} from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import { getYear, updateYear} from '../../lib/api-branch';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; 


const style = {
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "flex",
  height:"flex",
  borderRadius:"2%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '100%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));


const Year_setting = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const[open1,setOpen1] =useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const[rows, setRows] = useState([]);
  const[branchName,setBranchName] = useState('');
  const [searchText, setSearchText] = useState('');
  const[bID,setBid] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const[open2,setOpen2] =useState(false);
  const handleClose2 = ()=>setOpen2(false);
  const handleOpen2 = (branchId) =>
  {
    setOpen2(true);
    setBid(branchId);

  }
  const handleEdit = (row) =>{
    console.log("Hit : ", row);
  setSelectedRow(row);  // Store selected row
  // setSelectedDate(row.year); // If row has a date field, prefill it
  setOpen1(true);

  }
  const handleEditClose = () => {
    setOpen1(false);
    
  };
  
  const handleClose = () => {
    setOpen(false);
    
  };

  const handleUpdate = async() => {
    try {
      console.log("Formatted Data:", selectedDate);
      console.log("Row Data:", selectedRow);
      setLoading(true);
      const date = new Date(selectedDate);
const formattedDate = date.toLocaleDateString('en-CA', { timeZone: 'Asia/Kolkata' });
      console.log(formattedDate)
      const data = await updateYear({ ...selectedRow, year: formattedDate });
      
      console.log("Original Data:", data);
      setOpen(true)
      handleEditClose();
      fetchYear();

      
  } catch (error) {
      console.log("Error Fetching Year", error);
  } finally {
      setLoading(false);
  }
  }


  
  
  const fetchYear = async () => {
    try {
        setLoading(true);
        const data = await getYear();
        
        console.log("Original Data:", data);

        // Convert the year field in each object to local timezone
        const formattedData = data.map(item => ({
            ...item,
            year: new Date(item.year)
            .toLocaleDateString('en-GB', { timeZone: 'Asia/Kolkata' }) // Convert to IST
        }));

        console.log("Formatted Data:", formattedData);
        
        setRows(formattedData);
    } catch (error) {
        console.log("Error Fetching Year", error);
    } finally {
        setLoading(false);
    }
};

    useEffect(() =>
  {
    fetchYear();
  }, []);

  

  const columns = [
    { field: 'id', headerName: 'Sr.No.', flex: 1 },
    { field: 'year', headerName: 'Current Year', flex: 1 },
    { field: 'action', headerName: 'Action', flex: 0.24, renderCell: (params)=>(
      <div>
      <IconButton onClick={()=>handleEdit(params.row)}>
        <EditIcon sx={{color:'black'}}  />
      </IconButton>
      
    </div>
    ) }, 
   
  ];

  
  
  
  return (
    <Grid>
      {loading && <LoadingSpinner/>}
      <h1 style={{fontFamily:" Overpass, serif" , textAlign:"center", fontSize:"2rem"}}>Set Year</h1>  
    


    <div style={{paddingTop:"20px",height: 'auto', width: '100%' }}>
  <DataGrid
    density='compact'
    rows={rows}
    columns={columns}
    pageSize={5}
    rowsPerPageOptions={[5, 10, 20]}
   hideFooter
  />
</div>





    <Modal
      sx={{ maxWidth: 700, margin: "auto", marginTop: 2 }}
      open={open1}
      onClose={handleEditClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Grid container spacing={2}>
      
        <Grid item xs={12} md={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Select Date"
        value={selectedDate}
        onChange={(newValue) => setSelectedDate(newValue)}
        format="DD/MM/YYYY"
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
        </Grid>
       
        <Grid item xs={12} sx={{ marginTop: 2 }} onClick={()=>handleUpdate()}>
          <Button variant="contained" color="success" fullWidth >
              Update
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleEditClose}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal> 
    <Snackbar
        open={open}
        autoHideDuration={2000} // Closes after 2 seconds
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }} // Position
      >
        <Alert onClose={handleClose} severity="success" 
        variant="filled" // Makes it filled
        sx={{ 
          width: "auto",  // Adjusts width to content
          minWidth: "150px", // Ensures a minimum width
          fontSize: "0.75rem", // Makes text smaller
          padding: "4px 12px" // Reduces padding for compact size
        }}>
          Date Updated Successfully!
        </Alert>
      </Snackbar>
    

</Grid>
  )
}

export default Year_setting;