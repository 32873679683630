import axios from 'axios';
import { MAGNETIC_BASE_PATH } from './api-base-paths';

export const getAllMagnetics = () => {
    try {
      return axios.get(`${MAGNETIC_BASE_PATH}/getAllMagnetic`);
    } catch (error) {
      console.error('Error fetching all Magnetics', error);
      throw error;
    }
  };

  export const createMagnetic = (data) => {
    return axios.post(`${MAGNETIC_BASE_PATH}/createMagnetic`, data);
  };

  export const getMagneticByCertificateNo = (id) => {
    return axios.get(`${MAGNETIC_BASE_PATH}/getMagneticByCertificateNo/${id}`);
  }; 

  export const getAutoCertificateNo = (date) => {
    const data = {
      date: date
    };
    return axios.post(`${MAGNETIC_BASE_PATH}/getAutoCertificateNo`,data);
  }; 

  export const getMagneticById = (id) => {
    return axios.get(`${MAGNETIC_BASE_PATH}/getMagneticById/${id}`);
  }; 

  export const updateMagnetic = (id, data) => {
    return axios.put(`${MAGNETIC_BASE_PATH}/updateMagnetic/${id}`, data);
  };

  export const deleteMagnetic = (id) => {
    return axios.delete(`${MAGNETIC_BASE_PATH}/deleteMagnetic/${id}`);
  };  

  export const createMagneticDescription = (data) => {
    return axios.post(`${MAGNETIC_BASE_PATH}/createMagneticDescription`, data);
  };

  export const createMagneticMagneticMaterial = (data) => {
    return axios.post(`${MAGNETIC_BASE_PATH}/createMagneticMagneticMaterial`, data);
  };

  export const createMagneticModalTran = (data) => {
    return axios.post(`${MAGNETIC_BASE_PATH}/createMagneticModalTran`, data);
  };

  export const createMagneticSrTran = (data) => {
    return axios.post(`${MAGNETIC_BASE_PATH}/createMagneticSrTran`, data);
  };

  export const deleteMagneticDescription1 = (id) => {
    return axios.delete(`${MAGNETIC_BASE_PATH}/deleteMagneticDescription1/${id}`);
  }; 

  export const deleteMagneticTechniqueTran = (id) => {
    return axios.delete(`${MAGNETIC_BASE_PATH}/deleteMagneticTechniqueTran/${id}`);
  }; 

  export const deleteMagneticMagneticMaterial = (id) => {
    return axios.delete(`${MAGNETIC_BASE_PATH}/deleteMagneticMagneticMaterial/${id}`);
  }; 

  export const deleteMagneticModelTran = (id) => {
    return axios.delete(`${MAGNETIC_BASE_PATH}/deleteMagneticModelTran/${id}`);
  }; 

  export const deleteMagneticSrTran = (id) => {
    return axios.delete(`${MAGNETIC_BASE_PATH}/deleteMagneticSrTran/${id}`);
  }; 

  export const getMagneticDescriptionById = (id) => {
    return axios.get(`${MAGNETIC_BASE_PATH}/getMagneticDescriptionById/${id}`);
  }; 

  export const getMagneticMaterialUsedbyId = (id) => {
    return axios.get(`${MAGNETIC_BASE_PATH}/getMagneticMaterialUsedbyId/${id}`);
  }; 

  export const getMagneticModelbyId = (id) => {
    return axios.get(`${MAGNETIC_BASE_PATH}/getMagneticModelbyId/${id}`);
  }; 

  export const getMagneticSrNoById = (id) => {
    return axios.get(`${MAGNETIC_BASE_PATH}/getMagneticSrNoById/${id}`);
  }; 

  export const getMagneticTechniqueById = (id) => {
    return axios.get(`${MAGNETIC_BASE_PATH}/getMagneticTechniqueById/${id}`);
  }; 
  export const fetchMaterialLabels = (id) => {
    return axios.get(`${MAGNETIC_BASE_PATH}/fetchMaterialLabels/${id}`);
  }; 


  export const getMagneticPDF = (id) => {
    return axios.put(`${MAGNETIC_BASE_PATH}/getMagneticPDF/${id}`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    });
  };
  
  export const getPrint = async () => {
    try {
        const response = await axios.get(`${MAGNETIC_BASE_PATH}/getPrint`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all customer:', error);
        throw error;
    }
};