import axios from 'axios';
import { EDDY_BASE_PATH } from './api-base-paths';

export const getAllEddys = () => {
    try {
        return axios.get(`${EDDY_BASE_PATH}/getAllEddy`)  ;
    } catch (error) {
        console.error('Error fatching all Eddys', error);
    }
};

export const createEddy = (data) => {
  console.log("Hello from route", data);
    return axios.post(`${EDDY_BASE_PATH}/createEddy`, data);
  };

  export const getEddyByCertificateNo = (id) => {
    return axios.get(`${EDDY_BASE_PATH}/getEddyByCertificateId/${id}`);
  }; 

  export const getAutoCertificateNo = (date) => {
    const data = {
      date: date
    };
    return axios.post(`${EDDY_BASE_PATH}/getAutoCertificateNo`,data);
  }; 

  export const getEddyById = (id) => {
    return axios.get(`${EDDY_BASE_PATH}/getEddyById/${id}`);
  }; 

  export const updateEddy = (id, data) => {
    return axios.put(`${EDDY_BASE_PATH}/updateEddy/${id}`, data);
  };

  export const deleteEddy = (id) => {
    console.log('Data', id);
    return axios.put(`${EDDY_BASE_PATH}/deleteEddy/${id}`,  
    );
  };  

  export const createEddyDescription = (data) => {
    return axios.post(`${EDDY_BASE_PATH}/createEddyDescription`, data);
  };

  export const deleteEddyDescription = (id) => {
    return axios.delete(`${EDDY_BASE_PATH}/deleteEddyDescription/${id}`);
  };  

  export const getEddyDescriptionById = (id) => {
    return axios.get(`${EDDY_BASE_PATH}/getEddyDescriptionById/${id}`);
  }; 



  export const getEddyPDF = (id) => {
    return axios.put(`${EDDY_BASE_PATH}/getEddyPDF/${id}`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    });
  };

  export const getPrint = async () => {
    try {
        const response = await axios.get(`${EDDY_BASE_PATH}/getPrint`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all customer:', error);
        throw error;
    }
};