import axios from 'axios';
import { BRANCH_BASE_PATH } from './api-base-paths';

export const getAllBranch = async () => {
    try {
        const response = await axios.get(`${BRANCH_BASE_PATH}/getAllBranches`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all Branch:', error);
        throw error;
    }
};
export const getYear = async () => {
    try {
        const response = await axios.get(`${BRANCH_BASE_PATH}/getYear`);
        return response.data;
    } catch (error) {
        console.error('Error fetching current year:', error);
        throw error;
    }
};
export const updateYear = async (data) => {
    try {
        const response = await axios.post(`${BRANCH_BASE_PATH}/updateYear`,data);
        return response.data;
    } catch (error) {
        console.error('Error fetching current year:', error);
        throw error;
    }
};
export const createBranch = async (branchData) => {
    try {
        const response = await axios.post(`${BRANCH_BASE_PATH}/createBranch`, branchData);
        return response.data;
    } catch (error) {
        console.error('Error creating branch:', error);
        throw error;
    }
};


export const deleteBranch = async (branchId) => {
    try {
        const response = await axios.delete(`${BRANCH_BASE_PATH}/deleteBranch/${branchId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting branch:', error);
        throw error;
    }
};

export const updateBranch = async (branchId, branchData) => {
    try {
        const response = await axios.put(`${BRANCH_BASE_PATH}/updateBranch/${branchId}`, branchData);
        return response.data;
    } catch (error) {
        console.error('Error updating branch:', error);
        throw error;
    }
};

export const getBranchById = async (branchId) => {
    try {
        const response = await axios.get(`${BRANCH_BASE_PATH}/getBranchById/${branchId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching branch by ID:', error);
        throw error;
    }
};
