import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import imgX from '../../assets/logo.jpeg'
import imgY from '../../assets/vspace-logo.jpeg'
import './LoginStyle1.css';
import { FaUser ,FaLock } from "react-icons/fa";
import { Loginuser } from '../../lib/api-login';
import { useDispatch } from 'react-redux';
import CustomSnackbar from '../common/CustomSnackbar';

function Login() {
  const[userName,setUserName] = useState('');
  const[password,setPassword] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState("");
    const [color,setColor]=useState("")
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState('');
 const dispatch=  useDispatch()
  const savePreferences = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

  const navigate = useNavigate();
const data={
  userName,password
}
  
  const handleLogin = async(e) => {

    e.preventDefault();
    try {
      const response = await Loginuser(data);
      
         
        if (response.data.Status === 'Success') {
          console.log('Login successful');
          dispatch({ type: 'login' });
          localStorage.setItem('prefrences',response.data.username)
          localStorage.setItem('token', `token=${response.data.token}; path=/`);
          navigate('/Db');
          
        } else {
          console.error('Failed to fetch',response.data.Error);  
          setConfirmationMessage("Invalid username or password");
              setIsConfirmationOpen(true);
              setColor('error')
          setError('Invalid username or password'); 
        }
    
    } catch (error) {
      console.error(error);
      setError('Failed to login. Please try again.'); 
    }
  };

    // Check if the username and password are correct
  //   if (userName === 'Admin' && password === '123') {
  //     setIsLoggedIn(true);
  //     setError('');
  //     const prefrence = {name: userName };
  //     savePreferences('prefrences',prefrence);
  //   } else {
  //     setIsLoggedIn(false);
  //     setError('Invalid username or password');
  //   }
  // };

  useEffect(() => {
   
    if(isLoggedIn)
    {
     navigate('/Db');
    }
   
 }, [isLoggedIn]);  


  return (
   
  <div className='lx'>
        <div className='wrapper'>
            <img
              src={imgX}
              alt="Logo"
            className="logo"
          />
          {error && <p style={{ color: 'red' }}>{error}</p>}
     <form onSubmit={handleLogin}>
      <div className="input-box">
        <input type="text" placeholder='Username' value={userName} onChange={(e) => setUserName(e.target.value)} required/>
        <FaUser className='icon'/>
      </div>

      <div className="input-box">
        <input type="password" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} required/>
        <FaLock className='icon'/>
      </div>
      <button className='btn' type="submit"  onClick={handleLogin}>Login</button>
     </form>

     
     <img
             src={imgY}
            alt="Additional Image"
           className="additional-image"
            />
            
    </div>
    <CustomSnackbar
        open={isConfirmationOpen}
        message={confirmationMessage}
      color={color}
        onClose={() => setIsConfirmationOpen(false)}
      />
  </div>
   )
}

export default Login;

